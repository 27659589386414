<template>
  <div class="payment-waiting">
    <BankPaymentWaiting
      v-if="isBank"
      :currency="paymentAmount.currency"
      :payment-slug="payment.slug"
    />
    <CryptoPaymentWaiting v-else-if="payment.isCrypto()" />
    <ElectronicPaymentWaiting v-else-if="payment.isElectronic()" />
    <ApmPaymentWaiting v-else-if="payment.isApm()" />
    <Actions>
      <template
        v-if="
          hasPrev || payment.checkoutable() || transaction.isManualPayment()
        "
        #submit
      >
        <button
          v-if="hasPrev"
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="$router.push({ name: prev.name })"
        >
          {{ $t('shared.navigation.button.go-back') }}
        </button>
        <button
          v-if="payment.checkoutable()"
          :disabled="isLocked"
          class="btn btn-lg btn-primary"
          :class="{ 'is-loading': isLocked }"
          @click="checkout()"
        >
          {{ $t('shared.navigation.button.checkout') }}
        </button>
        <button
          v-if="transaction.isManualPayment()"
          :disabled="isLocked"
          class="btn btn-lg btn-primary"
          :class="{ 'is-loading': isLocked }"
          @click="markAsPaid()"
        >
          {{ $t('shared.navigation.button.payment-made') }}
        </button>
      </template>
    </Actions>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions.vue';
import BankPaymentWaiting from '../components/bank-payment-waiting.vue';
import CryptoPaymentWaiting from '../components/crypto-payment-waiting.vue';
import ElectronicPaymentWaiting from '../components/electronic-payment-waiting.vue';
import ApmPaymentWaiting from '../components/apm-payment-waiting.vue';

export default {
  components: {
    ApmPaymentWaiting,
    BankPaymentWaiting,
    CryptoPaymentWaiting,
    ElectronicPaymentWaiting,
    Actions,
  },
  computed: {
    ...mapGetters('quote', ['paymentAmount']),
    ...mapGetters('transaction/navigation', ['hasPrev', 'prev']),
    ...mapGetters('transaction', ['transaction', 'payment']),
    ...mapGetters(['isLocked']),
    isBank(){
      return this.payment.isBank() || this.payment.isBankSwift();
    },
  },
  methods: {
    ...mapActions('transaction', [
      'checkoutTransaction',
      'markTransactionAsPaid',
    ]),
    ...mapMutations(['lock', 'unlock']),
    checkout() {
      this.lock();
      this.checkoutTransaction();
    },
    markAsPaid() {
      this.lock();
      this.markTransactionAsPaid().finally(() => {
        this.unlock();
      });
    },
  },
};
</script>
