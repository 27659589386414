<template>
  <div class="cooling-off-user">
    <div class="cooling-off-user__inner">
      <i class="cooling-off-user__icon svg-icon icon-cancelled" />

      <span class="cooling-off-user__text">
        {{ message }}
      </span>

      <button
        class="btn btn-lg btn-primary"
        @click="$emit('close')"
      >
        {{ $t('widget.close') }}
      </button>
    </div>

    <SupportChat
      :chat-key="liveChatKey"
      :lang="currentLang"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SupportChat from '@/v1/components/SupportChat';

export default {
  name: 'TransactionCryptocurrencyIsNotAvailableModal',

  components: { SupportChat },

  setup() {
    const store = useStore();
    const liveChatKey = computed(() => store.getters.liveChatKey);
    const currentLang = computed(() => store.getters.locale);
    const message = computed(() => store.getters.messageCoolingOffUserPopup);

    return {
      liveChatKey,
      currentLang,

      message,
    };
  },
};
</script>

<style lang="scss" scoped>
.cooling-off-user {
  background-color: $white;
  max-width: 480px;
  border-radius: 6px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 56px 36px;
  }

  &__icon {
    width: 75px;
    height: 75px;
    margin-bottom: 16px;
  }

  &__text {
    display: block;
    margin-bottom: 32px;
    line-height: 1.4;
    text-align: center;
    font-family: $font-family;
    font-size: 18px;
  }
}
</style>
