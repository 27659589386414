import FormFactory from '../../factories/FormFactory';

const action = import.meta.env.VITE_APP_WORLDPAY_CHALLENGE_URL;

export default class ChallengeFormFactory {
  constructor({ jwt, md }) {
    this.jwt = jwt;
    this.md = md;
    this.iframeName = 'challenge';
  }

  build() {
    return {
      iframe: this.createIframe(),
      form: new FormFactory({
        // eslint-disable-next-line no-undef
        action,
        inputs: {
          MD: JSON.stringify(this.md),
          JWT: this.jwt,
        },
        method: 'POST',
        attributes: { target: this.iframeName },
      }).createFormElement(),
    };
  }

  buildForm() {
    const id = 'challengeForm';
    const element = document.body.querySelector(`form[id="${id}"]`);
    if (element) {
      element.parentNode.removeChild(element);
    }

    return new FormFactory({
      // eslint-disable-next-line no-undef
      action,
      inputs: {
        MD: JSON.stringify(this.md),
        JWT: this.jwt,
      },
      method: 'POST',
      attributes: { id },
    }).createFormElement();
  }

  createIframe() {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('name', this.iframeName);
    iframe.setAttribute('height', 250);
    iframe.setAttribute('width', 400);
    iframe.setAttribute('style', 'border: 0;');
    iframe.setAttribute('id', this.iframeName);

    return iframe;
  }
}
