import ApmApi from '@/v1/packages/common/services/clients/apm-client';

const PLUGIN_URL = `${import.meta.env.VITE_APP_TRUSTLY_URL}/start/scripts/paywithmybank.js`;
export default class TrustlyService {
  invoice;

  bankSessionId;

  session = null;

  constructor(invoice, bankSessionId = null) {
    this.invoice = invoice;
    this.bankSessionId = bankSessionId;
  }

  async createPayment() {
    const { data } = await ApmApi.trustlyCreatePayment({
      invoice: this.invoice,
      bankSessionId: this.bankSessionId,
    });
    return data;
  }

  async startSession() {
    const { data } = await ApmApi.trustlyInitSession({
      invoice: this.invoice,
      bankSessionId: this.bankSessionId,
    });
    data.returnUrl = window.location.hash;
    data.cancelUrl = window.location.hash;
    this.setSession(data);
    return data;
  }

  setSession(data) {
    this.session = data;
  }

  getSession() {
    return this.session;
  }

  initTrustly() {
    return new Promise(resolve => {
      const script = document.createElement('script');
      const encodedAccessId = encodeURIComponent(this.session?.accessId || '');
      script.src = `${PLUGIN_URL}?accessId=${encodedAccessId}`;
      script.onload = () => {
        const params = {
          widgetContainerId: 'trustly-widget',
        };
        if (this.bankSessionId) {
          Trustly.establish(this.session, params);
        } else {
          Trustly.selectBankWidget(this.session, params);
        }
        resolve();
      };
      document.head.appendChild(script);
    });
  }
}
