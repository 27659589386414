<template>
  <span v-if="!isLoading">
    <SumsubExtraVerification :field="sumsubActionField" />
  </span>
  <Loader v-else />
</template>

<script>
import Loader from '@/v1/packages/common/components/loader.vue';
import { mapActions, mapGetters } from 'vuex';

import SumsubExtraVerification from '../components/sumsub-extra-verification.vue';

const TYPE_SUMSUB_ACTION = 'sumsub-action';

export default {
  components: {
    SumsubExtraVerification,
    Loader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters('extraVerificationFields', ['fields']),
    sumsubActionField() {
      return this.fields.find(field => TYPE_SUMSUB_ACTION === field.getType());
    },
  },
  methods: {
    ...mapActions('sumsubExtraVerification', ['getConfig']),
  },
  mounted() {
    this.getConfig(this.sumsubActionField.id).then(() => {
      this.isLoading = false;
    });
  },
};
</script>
