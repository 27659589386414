<template>
  <span>
    <Paragraph
      v-if="hasConfirmation"
      v-html="
        t(
          'transaction-flow.steps.payment-waiting.crypto.confirmations',
          getCryptoViewModel.getConfirmations(),
          {
            confirmationCount: getCryptoViewModel.getConfirmations(),
          },
        )
      "
    />
    <Paragraph
      v-if="getCryptoViewModel.getExplorerLink()"
      class="text-grey"
      small
      v-html="linkToExplorer"
    />
  </span>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';

import { mapGetters } from 'vuex';
import CryptoViewModel from '../view-models/crypto';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Paragraph,
  },
  props: {
    payment: { type: Object, required: true },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payout']),
    getCryptoViewModel() {
      return new CryptoViewModel(this.payment.getDetails());
    },
    linkToExplorer() {
      return this.t(
        'transaction-flow.steps.payment-waiting.crypto.explorer.text',
        {
          link: `<a href="${this.getCryptoViewModel.getExplorerLink()}" target="_blank" class="btn-link">${this.t(
            'transaction-flow.steps.payment-waiting.crypto.explorer.link',
          )}</a>`,
        },
      );
    },
    isCardPayout() {
      return this.payout.isBankCard();
    },
    hasConfirmation() {
      return (
        !!this.getCryptoViewModel.getConfirmations() && !this.isCardPayout
      );
    },
  },
};
</script>
