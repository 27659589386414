<template>
  <Pictogram
    class="funnel-loader"
    :src="pictogramUrl"
  />
</template>

<script>
import Pictogram from '@/v1/packages/common/components/pictogram.vue';
import pictogramUrl from '@/v1/packages/common/assets/images/sales-funnel-loader.gif?url';

export default {
  components: {
    Pictogram,
  },
  data: () => ({
    pictogramUrl,
  }),
};
</script>

<style scoped lang="scss">
.funnel-loader {
  ::v-deep img {
    width: 140px;
  }
}
</style>
