<template>
  <div class="payment-status-waiting">
    <Title data-testid="payment-title">
      {{ $t('transaction-flow.payment-details.checking-your-info.title') }}
    </Title>

    <Pictogram :src="pictogramUrl" />
    <Paragraph :bold="true">
      {{
        $t(
          'transaction-flow.steps.payment-details.payment-status-waiting-screen.paragraph',
        )
      }}
    </Paragraph>
    <Loader class="mt-32 mb-32 ml-auto mr-auto" />
  </div>
</template>

<script>
import Title from '@/v1/packages/common/components/title.vue';
import Loader from '@/v1/packages/common/components/loader.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Pictogram from '@/v1/packages/common/components/pictogram.vue';
import pictogramUrl from '@/v1/packages/common/assets/images/verification-process.svg?url';

export default {
  name: 'PaymentStatusWaiting',

  components: {
    Title,
    Pictogram,
    Paragraph,
    Loader,
  },

  data: () => ({
    pictogramUrl,
  }),
};
</script>

<style lang="scss" scoped>
.payment-status-waiting {
  .sales-funnel-title {
    text-align: center;

    @media screen and (max-width: $tablet-max) {
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 1.25rem;
      margin-top: 10px;
    }
  }
}
</style>
