<template>
  <span>
    <Title>{{
      $t('transaction-flow.steps.card-authorization.pending.title')
    }}</Title>
    <Paragraph>{{
      $t('transaction-flow.steps.card-authorization.pending.message')
    }}</Paragraph>
  </span>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Title from '@/v1/packages/common/components/title.vue';

export default {
  components: {
    Title,
    Paragraph,
  },
};
</script>
