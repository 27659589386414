<template>
  <div>
    <div v-if="!isLoading">
      <Title
        v-if="!payout.isBankCard()"
        data-testid="wallettitle"
      >
        {{ $t(titleLabel) }}
      </Title>
      <Tabs
        v-if="payout.isCrypto()"
        @select="onTabSelect"
      >
        <Tab
          :title="tabLabelPaybisWallet"
          icon="paybis-logo"
          :is-selected="selectedTab"
          :is-disabled="!isPaybisWalletAvailable"
          data-testid="paybiswallet"
        />
        <Tab
          :title="tabLabelExternalWallet"
          icon="wallet"
          :is-selected="selectedTab"
          data-testid="externalwallet"
        />
      </Tabs>
      <Paragraph
        v-else-if="!payout.isBankCard()"
        v-html="$t('transaction-flow.steps.wallet.subtitle')"
      />
      <FormRow v-if="isPaybisWallet">
        <WalletSelect
          id="wallet"
          :value="paybisWallet"
          :options="availableWallets"
          name="wallet"
          :error="translateValidationMessage(accountField.getErrorMessage())"
          @input="onWalletSelectInput"
        />
      </FormRow>

      <CardPayoutDetails
        v-else-if="payout.isBankCard()"
        :transaction="transaction"
        :invoice="invoice"
      />
      <CustomFields
        v-else
        :fields="fields"
        @setField="setField"
      />
      <ZhComplianceCheckbox
        v-if="showComplianceCheckbox"
        :has-error="showComplianceError"
        @change="handleZhComplianceCheckbox"
      />
      <Actions v-if="!payout.isBankCard()">
        <template
          v-if="hasNext"
          #submit
        >
          <button
            :disabled="isLocked"
            class="btn btn-lg btn-primary"
            :class="{ 'is-loading': isLocked }"
            data-testid="continuebutton"
            @click="submit()"
          >
            {{ $t('shared.navigation.button.continue') }}
          </button>
        </template>
      </Actions>
    </div>
    <div
      v-else
      class="mt-32 mb-32"
    >
      <Loader class="ml-auto mr-auto" />
    </div>
  </div>
</template>

<script>
import Loader from '@/v1/packages/common/components/loader.vue';
import CustomFields from '@/v1/packages/common/components/custom-fields.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Tab from '@/v1/packages/common/components/tab.vue';
import Tabs from '@/v1/packages/common/components/tabs.vue';
import Title from '@/v1/packages/common/components/title.vue';
import { trimValue } from '@/v1/packages/common/services/util';
import FormRow from '@/v1/packages/common/components/form/form-row.vue';
import WebWalletsClient from '@/v1/packages/common/services/clients/web-wallets-client';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { captureMessage } from '@/v1/packages/common/plugins/sentry';
import Actions from '../components/actions.vue';
import WalletSelect from '../components/wallet-select.vue';
import ZhComplianceCheckbox from '../components/zero-hash-compliance-checkbox.vue';
import CardPayoutDetails from '../components/card-payout-details.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Loader,
    Paragraph,
    Title,
    Tabs,
    Tab,
    Actions,
    CustomFields,
    FormRow,
    WalletSelect,
    ZhComplianceCheckbox,
    CardPayoutDetails,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
      tabLabelPaybisWallet: t(
        'transaction-flow.steps.wallet.paybis-wallet',
      ),
      tabLabelExternalWallet: t(
        'transaction-flow.steps.wallet.my-own-wallet',
      ),
      selectedTab: t('transaction-flow.steps.wallet.my-own-wallet'),
      paybisWallet: '',
      availableWallets: [],
      isLoading: true,
      isAgreedWithZh: false,
      showComplianceError: false,
    };
  },
  computed: {
    ...mapGetters('transaction/navigation', ['hasNext']),
    ...mapGetters(['isLocked']),
    ...mapGetters('payoutDetailsFields', ['fields']),
    ...mapGetters('transaction', ['payout', 'payment', 'flowDetails', 'transaction', 'invoice']),
    ...mapGetters('feature', ['webWalletsFeature']),
    showComplianceCheckbox() {
      return this.flowDetails.isPayoutTermsAcceptanceRequired();
    },
    titleLabel() {
      return (
        `transaction-flow.steps.wallet.title.${
          this.payout.isCrypto() ? 'crypto' : 'non-crypto'}`
      );
    },
    isPaybisWallet() {
      return this.selectedTab === this.tabLabelPaybisWallet;
    },
    isPaybisWalletAvailable() {
      return this.availableWallets.length > 0;
    },
    accountField() {
      for (const field of this.fields) {
        if (field.isAccount()) {
          return field;
        }
      }
      return {};
    },
    defaultPaybisWalletAddress() {
      if (!this.isPaybisWalletAvailable) {
        return '';
      }

      const { addresses } = this.availableWallets[0];

      return addresses && addresses[0] ? addresses[0].address : '';
    },
  },
  methods: {
    ...mapActions('transaction', [
      'submitTransactionPayoutDetails',
      'setTransactionErrors',
    ]),
    ...mapActions('payoutDetailsFields', [
      'setFieldErrors',
      'resetFieldsErrors',
    ]),
    ...mapMutations(['lock', 'unlock']),
    ...mapMutations('verification', ['resetVerification']),
    ...mapMutations('payoutDetailsFields', ['updateField']),
    handleZhComplianceCheckbox(isAgreedWithZh) {
      this.isAgreedWithZh = isAgreedWithZh;
      this.showComplianceError = false;
    },
    submit() {
      this.lock();
      if (
        !this.isAgreedWithZh
        && this.flowDetails.isPayoutTermsAcceptanceRequired()
      ) {
        this.showComplianceError = true;
        this.unlock();

        return;
      }
      this.submitTransactionPayoutDetails({
        isAgreedWithZhCompliance: this.isAgreedWithZh,
        isPaybisAccount: this.isPaybisWallet,
      })
        .catch(({ data }) => {
          if (data && 'errors' in data) {
            this.setFieldErrors(data.errors);
          }

          return null;
        })
        .finally(() => {
          this.unlock();
        });
    },
    setField(field) {
      if (field.isAccount()) {
        const trimmedValue = trimValue(field.getValue());
        field.setValue(trimmedValue);
      }

      field.setErrorMessage(null);
      this.updateField(field);
    },
    async loadAvailableWallets() {
      const assetId = this.payout.getAssetId();

      try {
        const [wallet, supportedAssets] = await Promise.all([
          WebWalletsClient.getWalletsBy(assetId),
          WebWalletsClient.getSupportedAssets(),
        ]);

        const supportedAsset = supportedAssets.find(el => el.code === wallet.assetId);
        wallet.name = supportedAsset.getWalletName();

        this.availableWallets = [wallet];
      } catch (e) {
        const { response = {} } = e;

        if (response.status === 404) {
          throw e;
        }

        captureMessage({
          message: 'Failed to load available web-wallets',
          extra: {
            assetId,
            error: e,
          },
        });

        throw e;
      }
    },
    async addNewWallet() {
      const assetId = this.payout.getAssetId();

      try {
        await WebWalletsClient.addWallet({
          assetId,
        });
      } catch (e) {
        captureMessage({
          message: 'Failed to add new web-wallet',
          extra: {
            assetId,
            error: e,
          },
        });

        throw e;
      }
    },
    onWalletSelectInput(value) {
      this.paybisWallet = value;
      this.accountField.setValue(value);
    },
    onTabSelect(value) {
      this.resetFieldsErrors();
      this.selectedTab = value;
      if (this.isPaybisWallet) {
        this.onWalletSelectInput(this.defaultPaybisWalletAddress);
        return;
      }

      this.onWalletSelectInput('');
    },
    translateValidationMessage(message) {
      const translationSource = `validators.${message}`;
      const translation = this.t(translationSource);

      return translationSource === translation ? message : translation;
    },
  },
  async created() {
    if (!this.webWalletsFeature.isEnabled() || !this.payout.isCrypto()) {
      this.isLoading = false;
      return;
    }

    try {
      await this.loadAvailableWallets();
    } catch ({ response = {} }) {
      if (response.status !== 404) {
        // ToDo: need to handle different kinds of response errors
        this.isLoading = false;
        return;
      }
    }

    if (this.availableWallets.length > 0) {
      this.onWalletSelectInput(this.defaultPaybisWalletAddress);
      this.selectedTab = this.tabLabelPaybisWallet;
      this.isLoading = false;
      return;
    }

    try {
      await this.addNewWallet();
    } catch (e) {
      // ToDo: need to handle different kinds of response errors
      this.isLoading = false;
      return;
    }

    try {
      await this.loadAvailableWallets();
    } catch (e) {
      // ToDo: need to handle different kinds of response errors
      this.isLoading = false;
      return;
    }

    this.onWalletSelectInput(this.defaultPaybisWalletAddress);
    this.selectedTab = this.tabLabelPaybisWallet;
    this.isLoading = false;
  },
  beforeDestroy() {
    this.resetVerification();
  },
};
</script>

<style scoped lang="scss">
::v-deep .sales-funnel-paragraph {
  margin-bottom: rem(16);
}

::v-deep .sales-funnel-content {
  margin-top: 0;
}
</style>
