import { isInsideIframe } from '@/utils/isInsideIframe';
import { getPageUrlForIframe } from '@/utils/urls';
import store from '@/v1/store';

export function isSupportCrossOrigin() {
    const applePayConfig = store.getters.themeConfig.applePay;
    const crossOriginApplePaySessionFeature = store.getters['feature/crossOriginApplePaySessionFeature'].isEnabled();

    return crossOriginApplePaySessionFeature
        && isInsideIframe()
        && applePayConfig?.displayName
        && applePayConfig?.crossOrigin;
}

export function getCrossOriginParams() {
    const applePayConfig = store.getters.themeConfig.applePay;
    if (!isSupportCrossOrigin()) return {};
    return {
        domainName: getPageUrlForIframe(),
        displayName: applePayConfig?.displayName,
    };
}
