const MILLISECOND = 1000;
const TRANSACTION_STATUS_DEFAULT_REFRESH_TIME = 30 * MILLISECOND;

const LOWEST_TIME_IN_SECONDS_FOR_POLLING = 5;

const transactionStatusRefreshTime = () => {
  try {
    // Convert to int
    const refreshTime = import.meta.env.VITE_APP_SALES_FUNNEL_TRANSACTION_REFRESH_TIMEOUT;
    if (refreshTime < LOWEST_TIME_IN_SECONDS_FOR_POLLING) {
      // Do not allow low amount of seconds for polling
      return TRANSACTION_STATUS_DEFAULT_REFRESH_TIME;
    }

    // Convert to MS
    return refreshTime * MILLISECOND;
  } catch {
    // Suppress and use fallback
    return TRANSACTION_STATUS_DEFAULT_REFRESH_TIME;
  }
};

export const TRANSACTION_STATUS_REFRESH_TIME = transactionStatusRefreshTime();
