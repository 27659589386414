<template>
  <div class="loader-container" />
</template>

<script>

export default {
  name: 'CryptoAcquiringLoader',
};
</script>

<style scoped lang="scss">
.loader-container {
  min-width: 30px;
  min-height: 30px;

  &:after {
    min-width: inherit;
    min-height: inherit;
    display: block;
    content: "";
    background-image: url('@/v1/packages/common/assets/images/crypto-acquiring-loader.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
