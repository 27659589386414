<template>
  <div class="apple-pay-notification">
    <div class="apple-pay-notification__inner">
      <i class="apple-pay-notification__icon svg-icon icon-applepay-borderless" />

      <span class="apple-pay-notification__title">
        {{ $t('widget.apple-pay-device.title') }}
      </span>

      <span class="apple-pay-notification__text">
        {{ $t('widget.apple-pay-device.description') }}
      </span>

      <div class="apple-pay-notification__buttons">
        <div
          class="btn btn-lg btn-primary bold"
          @click="performRedirect"
        >
          {{ $t('widget.apple-pay-device.new-tab') }}
        </div>

        <div
          class="btn btn-lg btn-secondary bold"
          @click="$emit('reject')"
        >
          {{ $t('widget.apple-pay-device.current-tab') }}
        </div>
      </div>
    </div>

    <SupportChat
      :chat-key="liveChatKey"
      :lang="currentLang"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SupportChat from '@/v1/components/SupportChat';
import { TokenService } from '@/v1/overrides/AuthFlow/exports';
import { redirectToWidget } from '@/v1/utils/redirectToWidget';
import { captureMessage } from '@/v1/packages/common/plugins/sentry';

const KEYS = {
  JWT: '__jwt',
  JWT_EXPIRATION: '__jwt-exp',
  REFRESH_TOKEN: '__refresh_token',
  REFRESH_TOKEN_EXPIRATION: '__refresh_token-exp',
};

export default {
  name: 'ApplePayNotificationModal',

  components: { SupportChat },

  emits: ['confirm', 'reject'],

  setup(_, { emit }) {
    const store = useStore();
    const liveChatKey = computed(() => store.getters.liveChatKey);
    const currentLang = computed(() => store.getters.locale);

    const performRedirect = () => {
      const newTab = redirectToWidget({
        requestId: store.getters.requestId,
        locale: store.getters.locale,
      });

      try {
        newTab.localStorage.setItem(KEYS.JWT, TokenService.getToken());
        newTab.localStorage.setItem(KEYS.JWT_EXPIRATION, TokenService.getTokenExpirationString());
        newTab.localStorage.setItem(KEYS.REFRESH_TOKEN, TokenService.getRefreshToken());
        newTab.localStorage.setItem(
          KEYS.REFRESH_TOKEN_EXPIRATION,
          TokenService.getRefreshTokenExpirationString(),
        );
      } catch (e) {
        captureMessage({
          message: 'newTab.localStorage.setItem failed',
          extra: {
            error: e,
          },
        });
      }

      try {
        newTab.onbeforeunload = () => {
          const jwt = newTab.localStorage.getItem(KEYS.JWT);
          const jwtExpiresAt = newTab.localStorage.getItem(KEYS.JWT_EXPIRATION);
          const refreshToken = newTab.localStorage.getItem(KEYS.REFRESH_TOKEN);
          const refreshTokenExpiresAt = newTab.localStorage.getItem(KEYS.REFRESH_TOKEN_EXPIRATION);

          TokenService.setTokens({ jwt, refreshToken, jwtExpiresAt, refreshTokenExpiresAt });
        };
      } catch (e) {
        captureMessage({
          message: 'newTab.localStorage.getItem failed',
          extra: {
            error: e,
          },
        });
      }

      window.addEventListener('message', event => {
        const { data } = event;
        let eventData;

        try {
          eventData = JSON.parse(data);
        } catch {
          eventData = data;
        }

        const { namespace = '', name: eventName = '', payload = { } } = eventData;
        if (namespace !== 'widget') {
          return;
        }

        if (eventName === 'state' && payload.state === 'closed' && payload.action === 'logout') {
          TokenService.removeTokens();
          emit('confirm');
        }
      });

      window.addEventListener('focus', () => {
        emit('confirm');
      });
    };

    return {
      performRedirect,

      liveChatKey,
      currentLang,
    };
  },
};
</script>

<style lang="scss" scoped>
.apple-pay-notification {
  background-color: $white;
  max-width: 560px;
  border-radius: 6px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 56px 36px;
  }

  &__icon {
    width: 88px;
    height: 36px;
    margin-bottom: 48px;
  }

  &__title, &__text {
    display: block;
    margin-bottom: 32px;
    line-height: 1.4;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
  }

  &__text {
    font-family: $font-family;
    font-size: 18px;
  }

  &__buttons {
    display: flex;

    @media (max-width: $tablet-min) {
      flex-direction: column;
      width: 100%;
    }

    .btn + .btn {
      margin-left: 24px;

      @media (max-width: $tablet-min) {
        margin-left: 0;
        margin-top: 24px;
        width: 100%;
      }
    }
  }
}
</style>
