import * as Sentry from '@sentry/browser';

import store from '@/v1/store/index';

import { SumsubWebsdkErrorHandlerResult } from '../models/sumsub';

const IGNORE_WEBSDK_ERROR_CODES = ['camera-error'];

export const errorHandler = {
  handle(error, email) {
    store.commit('sumsubErrors/addError', error);

    if (IGNORE_WEBSDK_ERROR_CODES.includes(error.getCode())) {
      return SumsubWebsdkErrorHandlerResult.createHandled();
    }

    if (
      error.isRetryable()
      && store.getters['sumsubErrors/shouldRetry'](error)
    ) {
      return SumsubWebsdkErrorHandlerResult.createHandled();
    }

    Sentry.configureScope(scope => {
      scope.setContext('Paybis user', { email });
      scope.setContext('SumSub WebSDK error', {
        code: error.getCode(),
        message: error.getMessage(),
        'WebSDK Context': JSON.stringify(error.getContext()),
      });
    });
    Sentry.captureEvent({ message: `SumSub WebSDK error: ${error.getCode()}` });

    return SumsubWebsdkErrorHandlerResult.createFatal();
  },
};
