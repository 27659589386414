<template>
  <Content>
    <FormRow
      v-for="(field, i) in fields"
      :key="i"
    >
      <FormInput
        v-if="InputService.isInput(field)"
        :id="field.getId()"
        :required="!field.isOptional()"
        :value="field.getValue()"
        :name="field.getId()"
        :placeholder="field.getName()"
        :type="InputService.getFieldType(field)"
        :inputmode="InputService.getInputMode(field)"
        :min="InputService.getMinValue(field)"
        :max="InputService.getMaxValue(field)"
        :max-length="field.getMaxLength()"
        :step="InputService.getStep(field)"
        :has-error="field.hasError()"
        :error="translateValidationMessage(field.getErrorMessage())"
        :is-disabled="field.isDisabled()"
        :autocomplete="field.hasAutocomplete()"
        :data-testid="getLocator(field)"
        @input="setFieldValue(field, $event)"
        @click="$emit('onClick')"
        @blur="$emit('onBlur', field)"
      >
        <template
          v-if="field.getHint().hasValue()"
          #comment
        >
          {{ field.getHint().getValue() }}
        </template>
      </FormInput>
      <FormSelect
        v-else-if="InputService.isSelect(field)"
        :id="field.getId()"
        :required="!field.isOptional()"
        :options="InputService.getOptions(field)"
        :value="field.getValue()"
        :name="field.getId()"
        :label="field.getName()"
        :has-error="field.hasError()"
        :error="translateValidationMessage(field.getErrorMessage())"
        :is-disabled="field.isDisabled()"
        @input="setFieldValue(field, $event)"
      >
        <template
          v-if="field.getHint().hasValue()"
          #comment
        >
          {{ field.getHint().getValue() }}
        </template>
      </FormSelect>
      <FormBirthdayPicker
        v-else-if="InputService.isDateTime(field)"
        :id="field.getId()"
        :value="InputService.getValue(field)"
        :name="field.getId()"
        :label="field.getName()"
        :has-error="field.hasError()"
        :error="translateValidationMessage(field.getErrorMessage())"
        :is-disabled="field.isDisabled()"
        @input="setFieldValue(field, $event)"
      >
        <template
          v-if="field.getHint().hasValue()"
          #tip
        >
          {{ field.getHint().getValue() }}
        </template>
      </FormBirthdayPicker>
    </FormRow>
  </Content>
</template>

<script>
import moment from 'moment';

import InputService from '../services/input';
import Content from './content.vue';
import FormBirthdayPicker from './form/form-birthday-picker.vue';
import FormInput from './form/form-input.vue';
import FormRow from './form/form-row.vue';
import FormSelect from './form/form-select.vue';

import { CUSTOM_FIELDS_TO_LOCATORS_MAP } from '../constants/custom-fields';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Content,
    FormRow,
    FormInput,
    FormSelect,
    FormBirthdayPicker,
  },
  props: {
    fields: { type: Array, required: true },
    locators: { type: Object, required: false, default: () => ({}) },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  beforeCreate() {
    // Register service
    this.InputService = InputService;
  },
  methods: {
    translateValidationMessage(message) {
      const translationSource = `validators.${message}`;
      const translation = this.t(translationSource);

      return translationSource === translation || translation === ''
        ? message
        : translation;
    },
    setFieldValue(field, value) {
      const formattedValue = this.InputService.isDateTime(field)
        ? moment(value)
          .utcOffset(0, true)
          .format('MM/DD/YYYY')
        : value;

      field.setValue(formattedValue);
      this.$emit('setField', field);
    },
    errors(errors) {
      this.$emit(
        'errors',
        errors.map(error => {
          error.property_path = error.property_path.split('.')[2];
          return error;
        }),
      );
    },
    getLocator({ id }) {
      return CUSTOM_FIELDS_TO_LOCATORS_MAP[id] || null;
    },
  },
};
</script>
