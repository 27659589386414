<template>
  <div
    class="address-mask"
    :class="{
      bordered: hasBorder,
      plain: !hasBorder,
      'has-copy': hasCopy,
      'is-coppied': isCopied
    }"
    @click="handleCopy"
  >
    <div
      v-if="label"
      class="address-mask__label"
    >
      {{ label }}
    </div>

    <div
      class="address-mask__value-wrapper"
      :data-hover-label="address"
      :data-copy-label="copyLabel"
    >
      <div class="address-mask__value">
        <div>
          {{ maskAddress }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@/v1/packages/common/services/util';
import { useI18n } from 'vue-i18n';

const MIDDLE_STRING = '...';

export default {
  name: 'AddressMask',
  props: {
    hasBorder: { type: Boolean, required: false, default: false },
    address: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    hasCopy: { type: Boolean, required: false, default: true },
  },
  data() {
    const { t } = useI18n();

    return {
      t,
      textLength: 0,
      baseWidth: 0,
      isCopied: false,
      copyLabel: t('web-wallets.address.copied'),
    };
  },
  computed: {
    maskAddress() {
      if (!this.textLength) {
        return this.address;
      }
      const beginStrCount = Math.ceil(this.textLength / 2);
      const endStrCount = this.textLength - beginStrCount;

      const textStart = this.address.slice(0, beginStrCount);
      const textEnd = this.address.slice(-endStrCount);
      return this.replaceMiddle(`${textStart}${textEnd}`);
    },
  },
  watch: {
    baseWidth() {
      this.textLength = this.getTextLength();
    },
    address() {
      this.textLength = this.getTextLength();
    },
  },
  mounted() {
    this.setBaseWidth();
    window.addEventListener('resize', this.setBaseWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBaseWidth);
  },
  methods: {
    setBaseWidth() {
      this.baseWidth = this.$el.querySelector('.address-mask__value div').offsetWidth;
    },
    getTextLength() {
      const { baseWidth } = this;
      const auxDiv = this.createElement();
      let tmpText = '';
      let textLength = this.address.length;
      let newWidth = 0;

      // Check for small strings
      auxDiv.innerText = this.address;
      newWidth = auxDiv.offsetWidth;
      if (newWidth <= baseWidth) return;

      // Check for big strings
      for (let i = 1; i < this.address.length; i++) {
        const beginStr = this.address.slice(0, i);
        const endStr = this.address.slice(-i);
        tmpText = beginStr + endStr;
        auxDiv.innerText = tmpText;
        newWidth = auxDiv.offsetWidth;
        if (newWidth > baseWidth) {
          textLength = tmpText.length - 1;
          break;
        }
      }

      this.removeElement(auxDiv);
      return textLength;
    },
    replaceMiddle(string) {
      const rest = string.length - MIDDLE_STRING.length;
      return string.slice(0, Math.ceil(rest / 2)) + MIDDLE_STRING + string.slice(-Math.floor(rest / 2));
    },
    handleCopy() {
      if (!this.hasCopy) return;

      copyToClipboard(this.address);

      this.isCopied = true;

      setTimeout(() => {
        this.isCopied = false;
      }, 3000);
    },
    createElement() {
      const computedStyles = window.getComputedStyle(this.$el, null);

      const auxDiv = document.createElement('div');
      auxDiv.classList.add('auxdiv');
      auxDiv.style.fontFamily = computedStyles.getPropertyValue('font-family');
      auxDiv.style.fontSize = computedStyles.getPropertyValue('font-size');
      auxDiv.style.position = 'absolute';
      auxDiv.style.height = 'auto';
      auxDiv.style.marginLeft = '-1000px';
      auxDiv.style.marginTop = '-1000px';
      auxDiv.style.fontWeight = computedStyles.getPropertyValue('font-weight');
      auxDiv.style.width = 'auto';

      document.body.appendChild(auxDiv);

      return auxDiv;
    },
    removeElement(auxDiv) {
      document.body.removeChild(auxDiv);
    },
  },
};
</script>

<style scoped lang="scss">
.address-mask {
  width: 100%;
  position: relative;
  cursor: pointer;

  &.has-copy {
    cursor: copy;
  }

  &.has-copy:after {
    content: '\e925';
    @include inline-icon;
    position: absolute;
    right: 0;
    bottom: rem(0);
    transition: color 0.5s;
  }

  [dir='rtl'] &:after {
    left: 0;
    right: unset;
  }

  &__value-wrapper {
    position: relative;
  }

  &__value-wrapper:before {
    content: '';
    display: block;
    opacity: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: rem(2);
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(20);
    text-align: center;
    color: $white;
    padding: 0 rem(6);
    position: absolute;
    top: rem(-24);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s linear;
  }

  &__value-wrapper:hover:before {
    content: attr(data-hover-label);
    background-color: $black;
    opacity: 1;
  }

  &.has-copy.is-coppied &__value-wrapper:before {
    content: attr(data-copy-label);
    background-color: $green;
    opacity: 1;
  }

  &__label {
    text-align: left;
    font-size: rem(16);
    line-height: rem(20);
    margin-bottom: rem(6);
  }

  &.has-copy .address-mask__value {
    padding-right: 16px;
  }

  [dir='rtl'] &__label {
    text-align: right;
  }

  [dir='rtl'] &__value {
    padding: rem(14) rem(14) rem(16) rem(44);
  }
}

// Bordered
.address-mask.bordered {
  &:after {
    right: rem(16);
    bottom: rem(18);
  }

  [dir='rtl'] &:after {
    left: rem(16);
    right: unset;
  }

  .address-mask__value-wrapper:before {
    top: rem(-16);
  }

  .address-mask__value {
    font-size: rem(16);
    line-height: rem(20);
    pointer-events: none;
    user-select: none;
    min-width: 0;
    width: 100%;
    padding: rem(14) rem(44) rem(16) rem(14);
    border-radius: rem(6);
    box-shadow: 0 0 0 2px $black-10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: box-shadow 0.2s linear;
  }

  .address-mask__value-wrapper:hover &__value {
    box-shadow: 0 0 0 2px $black-20;
  }
}
</style>
