<template>
  <div class="waiting-confirmations">
    <FunnelLoader />

    <Title>{{ title }}</Title>

    <AutoProgressBar max-width="280px" />

    <div v-if="isPaymentSent">
      <Paragraph
        class=""
        v-html="$t('widget.transaction-flow.steps.waiting-confirmations.paragraph')"
      />

      <Paragraph
        small
        v-html="ourWalletLink"
      />
    </div>
  </div>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Title from '@/v1/packages/common/components/title.vue';
import FunnelLoader from '@/v1/packages/transaction/components/funnel-loader.vue';
import AutoProgressBar from '@/v1/packages/common/components/auto-progress-bar.vue';
import { mapGetters } from 'vuex';
import CryptoViewModel from '@/v1/packages/transaction/view-models/crypto';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    AutoProgressBar,
    FunnelLoader,
    Title,
    Paragraph,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payment']),
    crypto() {
      return new CryptoViewModel(this.payment.getDetails());
    },
    ourWalletLink() {
      return this.t(
        'transaction-flow.steps.payment-waiting.crypto.explorer.text',
        {
          link: `<a href="${this.crypto.getOurWalletLink()}" target="_blank" class="btn-link">${this.t(
            'transaction-flow.steps.payment-waiting.crypto.explorer.link',
          )}</a>`,
        },
      );
    },
    isPaymentSent() {
      return this.payment.status !== 'pending';
    },
    title() {
      return this.isPaymentSent
        ? this.t('widget.transaction-flow.steps.waiting-confirmations.title')
        : this.t('widget.transaction-flow.steps.waiting-payment.title');
    },
  },
};
</script>

<style lang="scss" scoped>
.waiting-confirmations {
  display: flex;
  flex-direction: column;
  // order for mobile
    @media  screen and (max-width: $mobile-min) {
      &__title {
        order: -1;
        text-align: left;
      }
    }
  }
</style>
