<template>
  <template v-if="isSelectedNew">
    <div className="card-payment-drawer">
      <TrustlyPaymentDetailsNew
        :trustly-inst="trustlyInst"
        @back="onBackWidget"
      />
    </div>
  </template>
  <template v-else>
    <Title data-testid="payment-title">
      {{ $t('transaction-flow.steps.trustly-payment-details.title') }}
    </Title>
    <Content>
      <AutoProgressBar
        v-if="isLoading"
        max-width="280px"
      />
      <template v-else>
        <p className="payment-select-title no-mob">
          {{ $t('transaction-flow.steps.trustly-payment-details.payment-select-title') }}
        </p>
        <FormRow>
          <PaymentSelect
            :payments="paymentMethods"
            :selected="selected"
            :is-loading="isLoading"
            :new-placeholder="$t('transaction-flow.trustly-payment-details.card-details.add-payment')"
            @selectPayment="handleSelectPayment"
            @pay="handlePayWithPaymentMethod"
          />
        </FormRow>
      </template>
      <RefundDisclaimer v-if="isShowRefundDisclaimer" />
    </Content>
    <TrustlyWidget
      v-if="isSessionExpired"
      :trustly-inst="trustlyInst"
      @ready="onReadySessionExpired"
    />
  </template>
</template>

<script setup>
import Title from '@/v1/packages/common/components/title.vue';
import AutoProgressBar from '@/v1/packages/common/components/auto-progress-bar.vue';
import Content from '@/v1/packages/common/components/content.vue';
import FormRow from '@/v1/packages/common/components/form/form-row.vue';
import store from '@/v1/store';
import { ref, computed, watch } from 'vue';
import PaymentSelect from './PaymentSelect/index.vue';
import RefundDisclaimer from './refund-disclaimer.vue';
import TrustlyService from '../services/trustlyInitService';
import TrustlyWidget from './TrustlyWidget/trustly-widget.vue';
import TrustlyPaymentDetailsNew from './trustly-payment-details-new.vue';

const props = defineProps({
  payment: { type: Object, required: true },
  invoice: { type: String, required: true },
});

const selected = ref('');
const trustlyInst = ref(new TrustlyService(props.invoice));
const isSessionExpired = ref(false);

const paymentMethods = computed(() => store.getters['apmPayment/trustlyPaymentMethods']);
const isShowRefundDisclaimer = computed(() => !isLoading.value);
const isSelectedNew = computed(() => selected.value === 'new');
const isLoading = computed(() => store.getters['apmPayment/isLoading']);

loadTrustlyPaymentMethods();

function loadTrustlyPaymentMethods() {
  store.dispatch('apmPayment/loadTrustlyPaymentMethods');
}

function chooseFirstAvailableMethod() {
  if (paymentMethods.value?.length > 0) {
    selected.value = paymentMethods.value[0].id;
  } else {
    selected.value = 'new';
  }
}

async function handlePayWithPaymentMethod({ id }) {
  store.commit('lock');
  trustlyInst.value = new TrustlyService(props.invoice, id);
  try {
    await trustlyInst.value.createPayment();
  } catch (e) {
    if (e.status === 400 && e.data.code === 'EXPIRED_SESSION') {
      isSessionExpired.value = true;
      return;
    }

    store.commit('unlock');
    throw new Error(e);
  }
}

function onReadySessionExpired() {
  store.commit('unlock');
}

async function handleSelectPayment(paymentId) {
  selected.value = paymentId;
}

function onBackWidget() {
  selected.value = '';
}

watch(
  isLoading,
  value => {
    if (!value) {
      chooseFirstAvailableMethod();
    }
  },
{ immediate: true },
);
</script>

<style lang="scss" scoped>
.payment-select-title {
  margin-bottom: rem(16);
  font-size: 0.875rem;
  line-height: 1.125rem;
}
</style>
