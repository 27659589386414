<template>
  <span>
    <Pictogram
      :src="pictogramUrl"
    />
    <Subtitle>{{ $t('transaction-flow.steps.success.subtitle') }}</Subtitle>
    <Paragraph
      v-if="payout.getExplorerLink()"
      class="text-grey"
      small
      v-html="linkToExplorer"
    />

    <FormRow style="margin-top: 16px">
      <CurrentTransaction
        :transaction="quote"
        type="equal"
      >
        <div v-if="!isCardPayout">{{ payout.getAccount() }}</div>
        <div>{{ $t('transaction-flow.wrapper.invoice') }}: {{ invoice }}</div>
      </CurrentTransaction>
    </FormRow>
    <Actions>
      <template #submit>
        <button
          class="btn btn-lg btn-secondary"
          @click="navigate()"
        >
          {{ $t('shared.navigation.button.view-all-transactions') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import CurrentTransaction from '@/v1/packages/common/components/current-transaction.vue';
import FormRow from '@/v1/packages/common/components/form/form-row.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Pictogram from '@/v1/packages/common/components/pictogram.vue';
import Subtitle from '@/v1/packages/common/components/subtitle.vue';
import UrlGenerator from '@/v1/packages/common/services/url';
import { mapGetters, mapMutations } from 'vuex';
import pictogramUrl from '@/v1/packages/common/assets/images/file-success.svg?url';

import Actions from '../components/actions.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Subtitle,
    Paragraph,
    Pictogram,
    CurrentTransaction,
    FormRow,
    Actions,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
      pictogramUrl,
    };
  },
  computed: {
    ...mapGetters('transaction', ['invoice', 'payout']),
    ...mapGetters('quote', ['quote']),
    ...mapGetters(['isLocked']),
    linkToExplorer() {
      const link = `<a href="${
        this.payout.getExplorerLink()
      }" target="_blank" class="btn-link">${
        this.t('transaction-flow.steps.success.crypto.explorer.link')
      }</a>`;

      return this.t('transaction-flow.steps.success.crypto.explorer.text', {
        link,
      });
    },
    isCardPayout() {
      return this.payout.isBankCard();
    },
  },
  methods: {
    ...mapMutations(['lock']),
    navigate() {
      this.lock();
      window.location = UrlGenerator.generate('/member/transactions/');
    },
  },
};
</script>
