<template>
  <div class="completed-header">
    <i class="svg-icon icon-success" />
    {{ $t('widget.completed.title') }}
  </div>

  <div class="completed-body">
    <template v-if="!payout.isBankCard() && funnelPayout.getExplorerLink() !== null">
      <p
        v-if="!payout.isInternalBinanceTransaction()"
        class="completed-details"
      >
        <i18n-t
          v-if="payout.isMobileCoin()"
          keypath="transaction-flow.steps.success.subtitle.mobilecoin"
        />

        <i18n-t
          v-else
          keypath="widget.completed.details.label"
        >
          <template #link>
            <a
              :href="funnelPayout.getExplorerLink()"
              target="_blank"
              class="btn-link link"
            >
              {{ $t("widget.completed.details.link-text") }}
            </a>
          </template>
        </i18n-t>
      </p>

      <p
        v-else
        class="completed-details"
      >
        {{
          $t("transaction-flow.steps.success.binance-id", {
            binanceId: payout.getBinanceId(),
          })
        }}
      </p>

      <ClampWalletAddress
        v-if="funnelPayout.getAccount() !== null"
        class="completed-creds"
      >
        {{ funnelPayout.getAccount() }}
      </ClampWalletAddress>
    </template>

    <p class="completed-creds">
      {{ $t('widget.completed.invoice-label') }}
      {{ funnelInvoiceId }}
    </p>

    <p
      v-if="payout.isInternalBinanceTransaction()"
      class="transaction-disclaimer"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="$t('transaction-flow.binance-internal-transaction-disclaimer')" />
    </p>

    <p
      v-if="showEmailNotice"
      class="sales-funnel-info"
    >
      <i class="sales-funnel-info__icon svg-icon icon-mailbox" />
      <span class="sales-funnel-info__text">
        {{ $t('widget.completed.email-notification') }}
      </span>
    </p>
  </div>

  <div class="completed-footer">
    <p class="completed-footer-info">
      {{ $t('widget.completed.info') }}
    </p>

    <MerchantRedirect
      v-if="successReturnURL"
      :url="successReturnURL"
    />
    <div class="buttons-group">
      <CloseWidgetButton
        mode="view"
        @click="closeWidget"
      />
      <BackToMerchantButton
        v-if="successReturnURL"
        :url="successReturnURL"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClampWalletAddress from '@/v1/components/ClampWalletAddress';

import { emitToParent, STATES } from '@/services/widgetApiEvents';
import CloseWidgetButton from '@/v1/components/CloseWidgetButton';
import BackToMerchantButton from '@/v1/components/BackToMerchantButton/BackToMerchantButton.vue';
import MerchantRedirect from '@/v1/components/MerchantRedirect/MerchantRedirect.vue';

export default {
  name: 'CustomCompleted',

  components: {
    MerchantRedirect,
    BackToMerchantButton,
    CloseWidgetButton,
    ClampWalletAddress,
  },

  computed: {
    ...mapGetters('external', ['funnelInvoiceId', 'funnelPayout']),
    ...mapGetters('transaction', ['payout', 'flowDetails']),
    ...mapGetters(['successReturnURL']),
    showEmailNotice() {
      return this.flowDetails.isZerohashPayoutStrategy();
    },
  },

  methods: {
    closeWidget() {
      emitToParent('state', {
        state: STATES.STATE_COMPLETED,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/v1/assets/scss/blocks/buttons-group";
@import "@/v1/assets/scss/blocks/link";

.completed {
  &-header {
    text-align: center;

    font-size: rem(28);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-body {
    text-align: center;
    margin-bottom: 40px;
  }

  &-footer {
    text-align: center;
  }

  &-footer-info {
    max-width: rem(370); // fix horozontal scroll on 1024x660 screen
    text-align: center;
    margin: 0 auto rem(24) auto;

    @media (max-width: $tablet-max) {
      width: auto;
    }
  }

  &-details {
    font-size: rem(16);
    line-height: rem(24);
    margin-bottom: rem(16);
  }

  &-creds {
    font-size: rem(14);
    line-height: rem(16);
    color: $grey-30;
    margin: 0 0 rem(4) 0;
    word-break: break-all;
  }
}

.transaction-disclaimer {
  font-size: rem(14);
  line-height: rem(16);
  color: $grey-30;
  margin-bottom: rem(4);
}

.icon-success {
  display: block;
  margin: rem(20) auto rem(24) auto;
  width: rem(96);
  height: rem(96);

  @media (max-width: $tablet-max) {
    width: 80px;
    height: 80px;
  }
}

.btn-link {
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &:hover {
    border-color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .completed {
    &-header {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
    }

    &-body {
      margin-bottom: calcVh(40, $widget-h-sm);
    }

    &-footer-info {
      margin: 0 auto calcVh(24, $widget-h-sm) auto;
    }

    &-details {
      font-size: calcVh(16, $widget-h-sm);
      line-height: calcVh(24, $widget-h-sm);
      margin-bottom: calcVh(16, $widget-h-sm);
    }

    &-creds {
      font-size: calcVh(14, $widget-h-sm);
      line-height: calcVh(16, $widget-h-sm);
      margin: 0 0 calcVh(4, $widget-h-sm) 0;
    }
  }

  .icon-success {
    margin: calcVh(20, $widget-h-sm) auto calcVh(24, $widget-h-sm) auto;
    width: calcVh(96, $widget-h-sm);
    height: calcVh(96, $widget-h-sm);
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .completed {
    &-header {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
    }
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .completed {
    &-header {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
    }
  }
}

.sales-funnel-info {
  display: flex;
  align-items: flex-start;
  padding: rem(12);
  background-color: $grey-f7;
  border-radius: rem(6);
  margin-top: rem(24);

  &__text {
    text-align: left;
    font-size: rem(14);
    line-height: rem(16);
  }

  &__icon {
    font-size: rem(16);
    margin-right: rem(8);
    width: rem(16);
    height: rem(12);
    background-size: contain;
    position: relative;
    top: 3px;
  }

  @media screen and (min-width: $screen-lg-min) {
    padding: rem(16);

    &__icon {
      margin-right: rem(16);
    }
  }
}

</style>
