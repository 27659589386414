<template>
  <div class="payment-check mt-32 mb-32">
    <Loader class="ml-auto mr-auto" />
  </div>
</template>

<script>
import Loader from '@/v1/packages/common/components/loader.vue';

export default {
  components: {
    Loader,
  },
};
</script>
