<template>
  <div
    class="header-title"
    :class="{
      'header-title--back': isBackButtonAvailable,
    }"
  >
    <button
      v-if="isBackButtonAvailable"
      class="header-title__back"
      @click="$emit('back')"
    >
      <i class="svg-icon icon-chevron-left-mask" />
    </button>

    <slot />
  </div>
</template>

<script>
// XXX: should be moved inside widget-front package after refactoring auth-app!
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderTitle',

  props: {
    isBackButtonAvailable: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.header-title {
  display: inline-block;
  width: 100%;
  font-size: rem(28);
  font-weight: 700;
  line-height: rem(32);
  margin-bottom: rem(40);
  text-align: center;
  position: relative;

  &__back {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    &:hover {
      opacity: 0.5;
    }

    i {
      height: 1rem;
      width: 1rem;
      display: block;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }
  }

  @media screen and (max-width: $tablet-max) {
    display: block;
    font-size: 24px;
    margin: 8px 0 24px;
    line-height: 26px;
    padding-right: 30px;
  }

  @media screen and (min-width: $laptop-min) {
    display: inline-block;
    width: 100%;
  }

  @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
    font-size: 3.6vh;
    line-height: 4.16vh;
    margin-bottom: 4vh;
  }

  @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
    font-size: 3.7vh;
    line-height: 4.18vh;
    margin-bottom: 7.31vh;
  }

  @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
    font-size: 3.71vh;
    line-height: 4.24vh;
    margin-bottom: 7.42vh;
  }

  @media screen and (max-width: $mobile-min) {
    &--back {
      padding-left: 20px;

      line-height: 20px;
      min-height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-title__back {
        height: 26px;
      }
    }
  }
}
</style>
