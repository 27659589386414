import store from '@/v1/store/index';
import { getLayout } from '@/services/widgetSettings';

export const getReturnUrl = () => {
  const parsedUrl = new URL(window.location.href);

  const searchParams = new URLSearchParams();
  searchParams.set('requestId', store.getters.requestId);
  searchParams.set('layout', getLayout());

  return `${parsedUrl.origin}${parsedUrl.pathname}?${searchParams.toString()}`;
};
