<template>
  <div class="payment-select__item">
    <div
      class="payment-select__item-header"
      @click="handleSelect"
    >
      <button
        class="payment-select__toggle"
        :class="{
          'is-selected': selected === 'new',
        }"
      >
        {{ $t("shared.select.select") }}
      </button>
      <div class="payment-select__item-icon">
        <img :src="plusIconUrl" />
      </div>
      <div class="payment-select__item-main">
        <div class="payment-select__item-placeholder">
          {{ placeholder }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import plusIconUrl from '@/v1/packages/common/assets/images/icon-plus.svg?url';

const props = defineProps({
  selected: {
    type: String,
    default: 'new',
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['selectPayment']);

function handleSelect() {
  emit('selectPayment', 'new');
}
</script>

<style lang="scss" scoped>
.payment-select {
  &__item {
    margin-left: rem(32);
    padding: rem(10) 0;
  }

  &__item-header {
    position: relative;
    display: flex;
    cursor: pointer;
  }

  [dir="rtl"] &__item {
    margin-right: rem(32);
    margin-left: unset;
  }

  &__item {
    border-bottom: 1px solid $black-10;
  }

  &__item-icon {
    display: flex;
    background-color: $grey-04;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);
  }

  &__item-icon img {
    width: rem(16);
    height: rem(16);
    margin: auto;
  }

  &__toggle {
    position: absolute;
    left: rem(-32);
    top: 50%;
    bottom: 50%;
    margin-top: rem(-8);
    font-size: 0;
    width: rem(16);
    height: rem(16);
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    background-color: #f5f5f5;
  }

  [dir="rtl"] &__toggle {
    right: rem(-32);
    left: unset;
  }

  &__toggle.is-selected:after {
    content: "";
    display: block;
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background-color: $black;
    margin: auto;
  }

  &__item-main {
    flex: 1;
    margin: 0 0 0 rem(16);
  }

  [dir="rtl"] &__item-main {
    margin: 0 rem(16) 0 0;
  }

  &__item-placeholder {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(40);
  }

  @media screen and (min-width: $screen-lg-min) {
    &__item-main {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      border-bottom: none;
    }

    &__item:not(:last-child) {
      border-bottom: 1px solid $black-10;
    }
  }
}
</style>
