<template>
  <div
    ref="dropdown"
    class="card-select-options"
  >
    <button
      class="card-select-options__dropdown-toggle"
      :class="{ 'is-open': isOpen }"
      @click.prevent="toggleDropdown"
    >
      <img
        class="card-select-options__dropdown-toggle__img"
        :src="iconUrl"
        alt="Dots icon"
      >
    </button>
    <div class="card-select-options__dropdown">
      <ul class="card-select-options__dropdown-list">
        <li>
          <button
            class="card-select-options__dropdown-item is-red"
            @click="deleteCard"
          >
            <Icon icon="trash" />
            {{
              $t('transaction-flow.payment-details.card-details.remove-card')
            }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from '@/v1/packages/common/components/icon.vue';
import iconUrl from '@/v1/packages/common/assets/images/icon-dots.svg?url';

export default {
  name: 'CardSelectOptions',

  components: {
    Icon,
  },

  data() {
    return {
      iconUrl,
      isOpen: false,
    };
  },

  destroyed() {
    document.removeEventListener('click', this.handleCloseAll);
  },

  methods: {
    deleteCard() {
      this.$emit('deleteCard');
      this.handleClose();
    },

    toggleDropdown(e) {
      // HACK: trigger click event on body to close all previously opened card select options
      document.body.click();

      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener('click', this.handleCloseAll);
      } else {
        document.removeEventListener('click', this.handleCloseAll);
      }
    },

    handleClose() {
      this.isOpen = false;
      document.removeEventListener('click', this.handleCloseAll);
    },

    handleCloseAll(e) {
      const el = this.$refs.dropdown;
      const { target } = e;

      if (el && el !== target && !el.contains(target)) {
        this.handleClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-select-options {
  position: relative;

  &__dropdown-toggle {
    width: rem(32);
    height: rem(32);
    color: $black-25;
    transition: color 0.2s linear;

    &__img {
      width: rem(16);
      height: rem(16);
      margin: auto;
    }
  }

  &__dropdown-toggle:hover {
    color: $violet;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99;
    background-color: $white;
    border: 1px solid $black;
    border-radius: rem(10);
    padding: rem(6);
    min-width: rem(156);
    opacity: 0;
    transform: translate3d(0, #{rem(-4)}, 0);
    visibility: hidden;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0.2s;
  }

  [dir='rtl'] &__dropdown {
    left: 0;
    right: unset;
  }

  &__dropdown-toggle.is-open ~ &__dropdown {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, transform 0.2s linear,
      visibility 0s linear 0s;
  }

  &__dropdown-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: rem(6);
    border-radius: rem(4);
    text-align: left;
    font-size: rem(14);
    font-weight: 700;
    white-space: nowrap;
    transition: background-color 0.2s linear;
  }

  &__dropdown-item.is-red {
    color: $red;
  }

  &__dropdown-item:hover {
    background-color: $grey-04;
  }

  &__dropdown-item .icon {
    margin-right: rem(8);
    color: $black;
  }

  [dir='rtl'] &__dropdown-item .icon {
    margin-left: rem(8);
    margin-right: unset;
  }
}
</style>
