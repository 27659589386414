<template>
  <div class="bank-details-warning">
    <Icon icon="info-circle" />
    <div>{{ $t(message) }}</div>
  </div>
</template>

<script>
import Icon from '@/v1/packages/common/components/icon.vue';

export default {
  name: 'BankDetailsWarning',
  components: {
    Icon,
  },
  props: {
    message: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
.bank-details-warning {
  margin-top: rem(4);
  font-size: rem(12);
  line-height: 1.33;
  color: $red;
  display: flex;
}

.icon {
  margin: 0 rem(4);
  line-height: rem(16);
}
</style>
