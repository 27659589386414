<template>
  <div class="banned-user">
    <div class="banned-user__inner">
      <i class="banned-user__icon svg-icon icon-cancelled" />

      <span class="banned-user__title">
        {{ $t('widget.banned-user-error') }}
      </span>

      <span class="banned-user__text">
        {{ $t('widget.banned-user-text') }}
      </span>

      <CloseWidgetButton
        mode="modal"
        @click="$emit('confirm')"
      />
    </div>

    <SupportChat
      :chat-key="liveChatKey"
      :lang="currentLang"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import SupportChat from '@/v1/components/SupportChat';
import CloseWidgetButton from '@/v1/components/CloseWidgetButton';

export default {
  name: 'BannedUserModal',

  components: { CloseWidgetButton, SupportChat },

  emits: ['confirm'],

  setup() {
    const store = useStore();
    const liveChatKey = computed(() => store.getters.liveChatKey);
    const currentLang = computed(() => store.getters.locale);

    return {
      liveChatKey,
      currentLang,
    };
  },
};
</script>

<style lang="scss" scoped>
.banned-user {
  background-color: $white;
  max-width: 480px;
  border-radius: 6px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 56px 36px;
  }

  &__icon {
    width: 75px;
    height: 75px;
    margin-bottom: 16px;
  }

  &__title, &__text {
    display: block;
    margin-bottom: 32px;
    line-height: 1.4;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
  }

  &__text {
    font-family: $font-family;
    font-size: 18px;
  }
}
</style>
