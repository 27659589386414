<template>
  <div class="payment-review">
    <h2 data-testid="payment-is-under-review" class="payment-review__title">
      {{ title }}
    </h2>
    <Paragraph>
      {{ message }}
    </Paragraph>
    <FunnelLoader />
    <CryptoPaymentData
      v-if="payment.isCrypto()"
      :payment="payment"
    />
  </div>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import { mapGetters } from 'vuex';

import CryptoPaymentData from '../components/crypto-payment-data.vue';
import FunnelLoader from '../components/funnel-loader.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Paragraph,
    CryptoPaymentData,
  },
  data() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  computed: {
    ...mapGetters('transaction', ['payment', 'payout']),
    title() {
      if (this.payout.isBankCard()) return this.t('transaction-flow.steps.payment-review.card-payout.title');

      return this.t('transaction-flow.steps.payment-review.title');
    },
    message() {
      if (this.payout.isBankCard()) return this.t('transaction-flow.steps.payment-review.card-payout.waiting-time');

      return this.t('transaction-flow.steps.payment-review.waiting-time');
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-review {
  &__title {
    text-align: center;
  }
}
</style>
