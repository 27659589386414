<template>
  <div class="secure-stamp">
    <img
      class="secure-stamp__img"
      :src="shieldIconUrl"
      alt="Shield icon"
    >
    <div>
      {{ $t('transaction-flow.accessory.payment') }}<br>{{
        $t('transaction-flow.accessory.secure')
      }}
    </div>
  </div>
</template>

<script>
import shieldIconUrl from '@/v1/packages/common/assets/images/icon-shield.svg?url';

export default {
  name: 'SecureStamp',
  data: () => ({
    shieldIconUrl,
  }),
};
</script>

<style scoped lang="scss">
.secure-stamp {
  display: flex;
  align-items: center;
  color: $black-25;
  font-size: rem(13);
  line-height: 1.08;

  &__img {
    width: rem(16);
    height: rem(16);
    margin-right: rem(8);

    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  [dir='rtl'] & svg {
    margin-left: rem(8);
    margin-right: unset;
  }
}
</style>
