<template>
  <span>
    <Title>{{ $t('transaction-flow.steps.extra-verification.title') }}</Title>
    <template v-if="showSumsubSdk">
      <div class="sumsub-websdk-wrapper">
        <SumsubWebsdk
          :api-url="sumsubConfig.apiUrl"
          :flow-name="sumsubConfig.flowName"
          :access-token="sumsubConfig.accessToken"
          :email="sumsubConfig.email"
          :language="language"
          :new-token-callback="newTokenCallback"
          @actionStatusCompleted="onActionStatusCompleted"
          @error="onError"
        />
      </div>
    </template>
    <template v-else-if="showPendingMessage">
      <Paragraph
        v-html="$t('transaction-flow.steps.extra-verification.review-subtitle')"
      />
      <div class="verification-form-approve__icon">
        <img
          :src="iconUrl"
          alt="icon"
        >
      </div>
      <Loader class="mt-32 mb-32 ml-auto mr-auto" />
      <Paragraph>{{
        $t('transaction-flow.steps.extra-verification.review-text')
      }}</Paragraph>
    </template>
  </span>
</template>

<script>
import Loader from '@/v1/packages/common/components/loader.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import SumsubWebsdk from '@/v1/packages/common/components/sumsub-websdk.vue';
import Title from '@/v1/packages/common/components/title.vue';
import { errorHandler as SumsubWidgetErrorHandler } from '@/v1/packages/common/services/sumsub';
import { mapActions, mapGetters } from 'vuex';
import iconUrl from '@/v1/packages/common/assets/images/verification-additional-icon.png?url';

import { STEP_ERROR } from '../models/steps';

export default {
  components: {
    Title,
    Paragraph,
    Loader,
    SumsubWebsdk,
  },
  props: {
    field: { type: Object, required: true },
  },
  data() {
    return {
      iconUrl,
      isSumsubActionCompleted: false,
    };
  },
  watch: {
    extraVerification(
      changedExtraVerification,
      oldExtraVerification,
    ) {
      if (
        !oldExtraVerification.isRejected()
        && changedExtraVerification.isRejected()
      ) {
        this.isSumsubActionCompleted = true; // if verification was just rejected, we cannot show WebSDK yet, need to load new config first
      }
      if (
        this.isSumsubActionCompleted
        && (changedExtraVerification.isPending()
          || changedExtraVerification.isRejected())
      ) {
        this.getConfig(this.field.id).then(() => {
          this.isSumsubActionCompleted = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters('sumsubExtraVerification', ['sumsubConfig']),
    ...mapGetters(['language']),
    ...mapGetters('transaction', ['extraVerification']),
    showSumsubSdk() {
      return (
        (this.extraVerification.isPending()
          || this.extraVerification.isRejected())
        && !this.isSumsubActionCompleted
      );
    },
    showPendingMessage() {
      return this.extraVerification.isReview() || this.isSumsubActionCompleted;
    },
  },
  methods: {
    ...mapActions('sumsubExtraVerification', ['getConfig']),
    onActionStatusCompleted() {
      this.isSumsubActionCompleted = true;
    },
    onError(error) {
      const handleResult = SumsubWidgetErrorHandler.handle(
        error,
        this.sumsubConfig.email,
      );
      if (handleResult.isFatal()) {
        this.$router.push({ name: STEP_ERROR });
      }
    },
    newTokenCallback() {
      return this.getConfig(this.field.id).then(() => this.sumsubConfig.accessToken);
    },
  },
};
</script>

<style scoped lang="scss">
.verification-form-approve {
  &__icon {
    margin: rem(4) 0 rem(24);
  }

  ::v-deep &__icon img {
    display: block;
    max-width: rem(100);
    margin: auto;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__icon {
      margin: rem(40) 0 rem(24);
    }
  }
}

.sumsub-websdk-wrapper {
  padding: 2.5em 0 1.5em 0;
}
</style>
