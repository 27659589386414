import WidgetService from '@/v1/services/widget';
import { AppCriticalError } from '@/exceptions/AppCriticalError';
import { REQUEST } from './mutations';
import { emitToParent } from '@/services/widgetApiEvents';
import userLocaleStorage from '@/v1/packages/authentication/services/user-locale-storage';
import { isSafari } from '@/v1/packages/common/services/safari';
import { addAppEventListener } from '@/window.events.js';

export default {
  namespaced: true,

  state: {
    email: null,
    invoiceId: null,
    quoteId: null,
    requestId: null,
    status: null,
    paymentMethod: '',
    payoutMethod: '',
    isPromoCodesEnabled: false,
    salesItemDescription: null,
    defaultCurrency: '',
    merchantCompanyName: null,

    isLoading: false,
  },

  getters: {
    email: ({ email }) => email,
    requestId: ({ requestId }) => requestId,
    invoiceId: (state, getters, globalState, globalGetters) => state.invoiceId || globalGetters['external/funnelInvoiceId'],
    hasInvoice: (state, getters) => typeof getters.invoiceId !== 'undefined' && getters.invoiceId !== null,
    quoteId: state => state.quoteId,
    wallet: state => state.cryptoWalletAddress || [],
    // eslint-disable-next-line max-len
    hasWallet: (state, getters) => getters.wallet && Array.isArray(getters.wallet) && getters.wallet.length > 0,
    paymentMethod: ({ paymentMethod }) => paymentMethod,
    paymentMethodSlug: ({ paymentMethod: paymentMethodSlug }) => paymentMethodSlug,
    payoutMethod: ({ payoutMethod }) => payoutMethod,
    payoutMethodSlug: ({ payoutMethod: payoutMethodSlug }) => payoutMethodSlug,
    requestStatus: ({ status }) => status,
    isPromoCodesEnabled: ({ isPromoCodesEnabled }) => isPromoCodesEnabled,
    merchantCompanyName: ({ merchantCompanyName }) => merchantCompanyName,
    salesItemDescription: ({ salesItemDescription }) => salesItemDescription,
    defaultCurrency: ({ defaultCurrency }) => defaultCurrency,

    isLoading: state => state.isLoading,
  },

  mutations: {
    [REQUEST.SET_REQUEST]: (state, data) => {
      state.email = data.email;
      state.invoiceId = data.invoiceId;
      state.quoteId = data.quoteId;
      state.requestId = data.requestId;
      state.status = data.status;
      state.isPromoCodesEnabled = data.isPromoCodesEnabled;
      state.merchantCompanyName = data.merchantCompanyName;
      state.defaultCurrency = data.defaultCurrency;
      const wallets = data.cryptoWalletAddress || [];
      state.cryptoWalletAddress = Array.isArray(wallets)
        ? data.cryptoWalletAddress : [data.cryptoWalletAddress];
      state.paymentMethod = data.paymentMethod;
      state.payoutMethod = data.payoutMethod;
      state.salesItemDescription = data.salesItemDescription;
    },

    [REQUEST.SET_QUOTE_ID]: (state, quoteId) => {
      state.quoteId = quoteId;
    },

    [REQUEST.SET_EMAIL]: (state, email) => {
      state.email = email;
    },

    [REQUEST.SET_IS_LOADING]: (state, loadingState) => {
      state.isLoading = loadingState;
    },

    [REQUEST.SET_INVOICE_ID]: (state, invoiceId) => {
      state.invoiceId = invoiceId;
    },

    [REQUEST.RESET_INVOICE_ID]: (state) => {
      state.invoiceId = null;
    },
  },

  actions: {
    async setRequest({ commit, dispatch }, { requestId }) {
      try {
        const data = await WidgetService.getRequest(requestId);

        data.requestId = requestId;

        commit(REQUEST.SET_REQUEST, data);
        userLocaleStorage.storeLocale(data.locale);

        if (data.invoiceId) {
          dispatch('external/setFunnelInvoiceId', {
            invoiceId: data.invoiceId,
          }, {
            root: true,
          });
        }

        return data;
      } catch ({ message }) {
        throw new AppCriticalError(message);
      }
    },
    setRequestV2({ commit, dispatch }, request) {
      try {
        commit(REQUEST.SET_REQUEST, request);
        userLocaleStorage.storeLocale(request.locale);

        if (request.invoiceId) {
          dispatch('external/setFunnelInvoiceId', {
            invoiceId: request.invoiceId,
          }, {
            root: true,
          });
        }
      } catch ({ message }) {
        throw new AppCriticalError(message);
      }
    },

    setRequestWithLoader({ commit, dispatch }, { requestId }) {
      commit(REQUEST.SET_IS_LOADING, true);
      return dispatch('setRequest', {
        requestId,
      }).finally(() => {
        commit(REQUEST.SET_IS_LOADING, false);
      });
    },

    async redirectToInvoice({ commit, dispatch }, { invoiceId }) {
      commit(REQUEST.SET_IS_LOADING, true);

      emitToParent('state', { state: 'showLoader' });

      try {
        const { requestId } = await WidgetService.getRequestIdByInvoice({
          invoiceId,
        });

        const toUrl = new URL(window.location.toString());

        toUrl.searchParams.set('requestId', requestId);

        // XXX: to prevent unlimited loader and unnecessary refresh when we decide to switch on current transaction
        if (toUrl.toString() === window.location.toString()) {
          commit('menu.set.state', false, { root: true });

          emitToParent('state', {
            state: 'loaded',
          });

          return;
        }

        toUrl.hash = undefined;

        addAppEventListener(isSafari() ? 'pagehide' : 'beforeunload', () => emitToParent('state', {
          state: 'hideLoader',
        }));

        window.location.assign(toUrl.toString());
      } catch {
        emitToParent('state', {
          state: 'loaded',
        });
      } finally {
        commit(REQUEST.SET_IS_LOADING, false);
      }
    },

    assignQuoteId({ commit }, quoteId) {
      commit(REQUEST.SET_QUOTE_ID, quoteId);
    },

    updateEmail({ commit }, email) {
      commit(REQUEST.SET_EMAIL, email);
    },
  },
};
