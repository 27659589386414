<template>
  <div class="tabs">
    <div class="tabs__head">
      <div class="tabs__head-scroll">
        <div class="tabs__head-inner">
          <button
            v-for="(tab, i) in tabList"
            :key="i"
            class="tabs__toggle"
            :class="{
              'is-selected': tab.title === selected,
              'is-disabled': tab.isDisabled
            }"
            :data-testid="tab.dataTestid"
            @click="handleSelect(tab)"
          >
            <Icon
              v-if="tab.icon"
              :icon="tab.icon"
            />
            {{ tab.title }}
          </button>
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import Icon from './icon.vue';

export default {
  name: 'Tabs',

  components: {
    Icon,
  },

  data() {
    return {
      tabList: [],
      selected: '',
    };
  },

  created() {
    const slots = this.$slots.default();
    const propsAccessor = 'props';

    slots.forEach(tab => {
      if (!tab.tag) return;

      const tabProps = tab[propsAccessor];

      this.tabList.push(tabProps);
    });

    this.selected = slots[0][propsAccessor]['is-selected'];
  },

  methods: {
    handleSelect(selectedTab) {
      if (selectedTab.isDisabled) {
        return false;
      }

      this.selected = selectedTab.title;

      this.$emit('select', selectedTab.title);
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  &__head {
    display: flex;
    justify-content: center;
    padding: rem(16) 0;
    margin-bottom: rem(4);
  }

  &__head-scroll {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 rem(-16);
  }

  &__head-inner {
    /** START: Quick-fix to display items on new line, if tab title is too long **/
    /*display: inline-flex;*/
    /*justify-content: center;*/
    display: block;
    text-align: center;
    /** END of quick-fix **/
    margin: auto;
    padding: 0 rem(16);
  }

  &__toggle {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(32);
    display: inline-flex;
    align-items: center;
    padding: 0 rem(8);
    border-radius: rem(4);
    white-space: nowrap;
    transition: background-color 0.2s linear;
  }

  &__toggle + &__toggle {
    margin-left: rem(10);
  }

  [dir="rtl"] &__toggle + &__toggle {
    margin-right: rem(10);
    margin-left: unset;
  }

  &__toggle.is-disabled {
    color: $grey-30;
    cursor: auto;
  }

  &__toggle.is-selected {
    background-color: $beige;
  }

  &__toggle .icon {
    margin-right: rem(8);
  }

  [dir="rtl"] &__toggle .icon {
    margin-left: rem(8);
    margin-right: unset;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__head {
      margin-bottom: rem(16);
    }
  }
}
</style>
