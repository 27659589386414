import FormFactory from '../../factories/FormFactory';

const action = import.meta.env.VITE_APP_WORLDPAY_DDC_URL;

export default class DDCFormFactory {
  constructor({ bin, jwt }) {
    this.bin = bin;
    this.jwt = jwt;
    this.iframeName = 'ddc';
  }

  build() {
    const name = this.iframeName;
    const element = document.body.querySelector(`iframe[name="${name}"]`);
    if (element) {
      element.parentNode.removeChild(element);
    }

    return {
      iframe: this.createIframe(),
      form: new FormFactory({
        // eslint-disable-next-line no-undef
        action,
        inputs: { Bin: this.bin, JWT: this.jwt },
        method: 'POST',
        attributes: { target: name },
      }).createFormElement(),
    };
  }

  createIframe() {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('name', this.iframeName);
    iframe.setAttribute('height', 1);
    iframe.setAttribute('width', 1);
    iframe.setAttribute('style', 'display: none;');

    return iframe;
  }
}
