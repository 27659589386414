<template>
  <div class="card-form">
    <FrameWrap
      v-if="cardEntryIFrameAppFeature.isEnabled()"
      ref="frame"
      width="100%"
      height="569px"
      :url="cardEntryIframe"
      :props="props"
      :translations="translations"
      :theme="theme"
      :actions="['submit']"
      :emits="['card', 'error']"
      @card="cardCreatedHandler"
      @error="createCardErrorHandler"
      @ready="iframeReadyHandler"
    />
    <template v-else>
      <FormRow v-if="showCardholderName">
        <FormInput
          :id="fields.cardholderName.getId()"
          :value="fields.cardholderName.getValue()"
          :name="fields.cardholderName.getName()"
          :type="fields.cardholderName.getType()"
          :label="$t('transaction-flow.payment-details.card-details.cardholder-name')"
          :has-error="fields.cardholderName.hasError()"
          :error="$t(`validators.${fields.cardholderName.getErrorMessage()}`)"
          data-testid="cardholder-name"
          max-length="26"
          min-length="2"
        >
          <template #default="{ attrs, listeners }">
            <input
              v-imask="cardholderNameMask"
              v-bind="attrs"
              v-on="listeners"
              @accept="validateAndUpdate($event, fields.cardholderName)"
            >
          </template>
        </FormInput>
      </FormRow>

      <FormRow>
        <FormInput
          :id="fields.cardNumber.getId()"
          :value="fields.cardNumber.getValue()"
          :name="fields.cardNumber.getName()"
          :type="fields.cardNumber.getType()"
          :label="$t('transaction-flow.payment-details.card-details.card-number')"
          :has-error="fields.cardNumber.hasError()"
          :error="$t(`validators.${fields.cardNumber.getErrorMessage()}`)"
          :logo="cardType"
          inputmode="numeric"
          data-testid="card-input"
        >
          <template #fixedTip>
            <img
              :src="cardLogoUrl"
              alt="card logo"
            >
          </template>
          <template #default="{ attrs, listeners }">
            <input
              v-imask="cardNumberMask"
              v-bind="attrs"
              v-on="listeners"
              @accept="validateAndUpdate($event, fields.cardNumber)"
            >
          </template>
        </FormInput>
      </FormRow>

      <FormRow media="xs">
        <FormRowSlot
          media="xs"
          width="50%"
          gap="wide"
        >
          <FormInput
            :id="fields.expirationDate.getId()"
            inputmode="numeric"
            class="card-form__expiration-date"
            :value="fields.expirationDate.getValue()"
            :name="fields.expirationDate.getName()"
            :label="$t('transaction-flow.payment-details.card-details.expiration-date')"
            :has-error="fields.expirationDate.hasError()"
            :error="$t(`validators.${fields.expirationDate.getErrorMessage()}`)"
            placeholder="MM/YY"
            data-testid="date-input"
          >
            <template #default="{ attrs, listeners }">
              <input
                v-imask="expirationDateMask"
                v-bind="attrs"
                v-on="listeners"
                @accept="validateAndUpdate($event, fields.expirationDate)"
              >
            </template>
          </FormInput>
        </FormRowSlot>

        <FormRowSlot
          v-if="!isPayout"
          media="xs"
          width="50%"
          gap="wide"
        >
          <FormInput
            :id="fields.cvv.getId()"
            :value="fields.cvv.getValue()"
            :name="fields.cvv.getName()"
            :type="fields.cvv.getType()"
            :label="$t('transaction-flow.payment-details.card-details.cvv')"
            :has-error="fields.cvv.hasError()"
            :error="$t(`validators.${fields.cvv.getErrorMessage()}`)"
            inputmode="numeric"
            data-testid="cvv-input"
          >
            <template #default="{ attrs, listeners }">
              <input
                v-imask="cvvMask"
                v-bind="attrs"
                v-on="listeners"
                @accept="validateAndUpdate($event, fields.cvv)"
              >
            </template>
          </FormInput>
        </FormRowSlot>
      </FormRow>

      <BillingAddressForm :billing-address-fields="billingAddressFields" />

      <div
        v-if="showWarning"
        class="card-form-agreement"
      >
        <FormCheckbox
          :id="fields.save.getId()"
          :value="fields.save.getValue()"
          :name="fields.save.getName()"
          error=""
          data-testid="save-card-checkbox"
          @change="validateAndUpdateCheckbox($event.target.checked, fields.save)"
        >
          <template #label>
            <span v-if="isPayout">
              {{ $t('transaction-flow.payment-details.card-details.save-my-card-label-for-transactions') }}
            </span>
            <span v-else>
              {{ $t('transaction-flow.payment-details.card-details.save-my-card-label') }}
            </span>
          </template>
          <template #description>
            <span v-if="isPayout">
              {{ $t('transaction-flow.payment-details.card-details.save-my-card-description-for-transactions') }}
            </span>
            <span v-else>
              {{ $t('transaction-flow.payment-details.card-details.save-my-card-description') }}
            </span>
          </template>
        </FormCheckbox>
      </div>
    </template>
  </div>
</template>

<script>
import FormCheckbox from '@/v1/packages/common/components/form/form-checkbox.vue';
import FormInput from '@/v1/packages/common/components/form/form-input.vue';
import FormRow from '@/v1/packages/common/components/form/form-row.vue';
import FormRowSlot from '@/v1/packages/common/components/form/form-row-slot.vue';
import { findCardType } from '@/v1/packages/common/services/util';
import luhn from 'luhn';
import { IMaskDirective } from 'vue-imask';
import { mapGetters } from 'vuex';
import BillingAddressForm from './billing-address-form.vue';
import FrameWrap from '@/v1/packages/common/components/frame-wrap.vue';
import TokenService from '@/v1/packages/authentication/services/token';
import { toRaw } from 'vue';
import { refreshToken } from '@/v1/overrides/AuthFlow/authInterceptors';
import { useI18n } from 'vue-i18n';
import cardLogoUrl from '@/v1/packages/common/assets/images/payment-logo.svg?url';

export default {
  name: 'CardNewForm',

  directives: { imask: IMaskDirective },

  components: {
    BillingAddressForm,
    FormRow,
    FormRowSlot,
    FormInput,
    FormCheckbox,
    FrameWrap,
  },

  props: {
    fields: { type: Object, required: true },
    billingAddressFields: { type: Object, required: true },
    invoice: { type: String, required: true },
    flow: { type: String, required: true },
    isPayout: { type: Boolean, default: false },
  },

  data() {
    const { t } = useI18n();

    return {
      t,
      cardLogoUrl,
      showWarning: true,
      timeoutZipId: null,
      cardEntryIframe: import.meta.env.VITE_APP_CARD_PAYMENT_FRONT_URL,
    };
  },

  computed: {
    ...mapGetters('feature', [
      'cardholderNameFeature',
      'cardEntryIFrameAppFeature',
    ]),
    ...mapGetters('transaction', ['transaction', 'baseVerification']),
    ...mapGetters(['themeConfig']),
    cardholderNameMask() {
      return {
        mask: /^(?![.'`~ ])(?!.*([^\w])\1)[a-z .'`~]+$/i,
      };
    },
    cardNumberMask() {
      return {
        mask: 'N N N N',
        blocks: {
          N: {
            mask: '0000',
          },
        },
      };
    },
    expirationDateMask() {
      return {
        mask: 'MM/YY',
        blocks: {
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          YY: {
            mask: '00',
          },
        },
      };
    },
    cvvMask() {
      return {
        mask: '000',
      };
    },
    props() {
      return {
        token: TokenService.getToken(),
        invoice: this.invoice,
        showCardholderName: this.showCardholderName,
        showCvv: !this.isPayout,
        availableCardType: ['visa', 'mastercard'],
        emits: ['card'],
      };
    },
    translations() {
      return {
        card: {
          name: this.t('transaction-flow.payment-details.card-details.cardholder-name'),
          number: this.t('transaction-flow.payment-details.card-details.card-number'),
          exp: this.t('transaction-flow.payment-details.card-details.expiration-date'),
          cvv: this.t('transaction-flow.payment-details.card-details.cvv'),
        },
        address: {
          title: this.t('transaction-flow.payment-details.card-details.billing-address-header'),
          country: this.t('transaction-flow.payment-details.card-details.country'),
          countryTooltip: this.$t('transaction-flow.payment-details.card-details.country-hint'),
          state: this.t('transaction-flow.payment-details.card-details.state'),
          address: this.t('transaction-flow.payment-details.card-details.address'),
          city: this.t('transaction-flow.payment-details.card-details.city'),
          zip: this.t('transaction-flow.payment-details.card-details.zip'),
        },
        errors: {
          cardNumber: this.t('validators.Please check your card number'),
          expirationDate: this.t('validators.Please check your card expiration date'),
          cvv: this.t('validators.3 digits required'),
          cardType: this.t('validators.transaction-flow.payment-details.card-details.only-visa-mastercard'),
        },
        save: {
          title: this.isPayout
            ? this.t('transaction-flow.payment-details.card-details.save-my-card-label-for-transactions')
            : this.t('transaction-flow.payment-details.card-details.save-my-card-label'),
          description: this.isPayout
            ? this.t('transaction-flow.payment-details.card-details.save-my-card-description-for-transactions')
            : this.t('transaction-flow.payment-details.card-details.save-my-card-description'),
        },
      };
    },
    theme() {
      return {
        fonts: toRaw(this.themeConfig.cardForm?.css?.fonts),
        vars: {
          textFont: this.themeConfig.cardForm?.css?.vars?.textFont,
          textColor: this.themeConfig.cardForm?.css?.vars?.textColor,
          inputFont: this.themeConfig.cardForm?.css?.vars?.inputFont,
          inputColor: this.themeConfig.cardForm?.css?.vars?.inputColor,
          inputBackgroundColor: this.themeConfig.cardForm?.css?.vars?.inputBackgroundColor,
          inputDisabledBackgroundColor: this.themeConfig.cardForm?.css?.vars?.inputDisabledBackgroundColor,
          inputBorderColor: this.themeConfig.cardForm?.css?.vars?.inputBorderColor,
          inputDisabledBorderColor: this.themeConfig.cardForm?.css?.vars?.inputDisabledBorderColor,
          inputHoverBorderColor: this.themeConfig.cardForm?.css?.vars?.inputHoverBorderColor,
          inputFocusBorderColor: this.themeConfig.cardForm?.css?.vars?.inputFocusBorderColor,
          inputBorderWidth: this.themeConfig.cardForm?.css?.vars?.inputBorderWidth,
          inputPlaceholderColor: this.themeConfig.cardForm?.css?.vars?.inputPlaceholderColor,
          optionSelected: this.themeConfig.cardForm?.css?.vars?.optionSelected,
          cardSaveBackgroundColor: this.themeConfig.cardForm?.css?.vars?.cardSaveBackgroundColor,
          cardSaveDescriptionColor: this.themeConfig.cardForm?.css?.vars?.cardSaveDescriptionColor,
          billingAddressTitleColor: this.themeConfig.cardForm?.css?.vars?.billingAddressTitleColor,
        },
      };
    },
    cardType() {
      return findCardType(this.fields.cardNumber.getValue()) || '';
    },
    showCardholderName() {
      return (
        this.cardholderNameFeature.isEnabled()
        || (this.transaction.isNewFLow() && !this.baseVerification.isApproved())
      );
    },
  },
  mounted(){
    if (!this.cardEntryIFrameAppFeature.isEnabled()) {
      this.$emit('ready');
    }
  },
  methods: {
    validateAndUpdateCheckbox(value, field) {
      field.setErrorMessage(null);
      field.setValue(value);
    },
    validateAndUpdate(e, field) {
      const value = e.detail?._value ?? e.target._value;

      field.setErrorMessage(null);

      if (
        field.getId() === 'card-number'
        && value.length > 15
        && !luhn.validate(value)
      ) {
        field.setErrorMessage('Please check your card number');
      }

      if (field.getId() === 'expiration-date') {
        const currentYear = new Date().getFullYear().toString().substr(2);
        const currentMonth = new Date().getMonth() + 1;
        const [month, year] = value.split('/');
        if (
          (year && year.length === 2 && year < currentYear)
          || (year === currentYear && month < currentMonth)
          || month > 12
        ) {
          field.setErrorMessage('Please check your card expiration date');
        }
      }

      field.setValue(value);
    },
    cardCreatedHandler(payload) {
      this.$emit('created', payload);
    },
    createCardErrorHandler(error) {
      this.$emit('error', error);
    },
    iframeReadyHandler() {
      this.$emit('ready');
    },
    async submit() {
      await refreshToken();
      this.$refs.frame.submit(TokenService.getToken(), this.flow);
    },
  },
};
</script>

<style scoped lang="scss">
.card-form {
  width: 100%;

  &__logos {
    display: flex;
    margin: 0 rem(-6);
  }

  &__logos-item {
    width: rem(24);
    height: rem(24);
    margin: 0 rem(6);
  }

  &__logos-item img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &__warning {
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(16);
    color: $yellow;
    background-color: $yellow-15;
    padding: rem(8) rem(10);
    border-radius: rem(6);
  }

  &__warning-close {
    position: absolute;
    top: rem(6);
    right: rem(6);
    width: rem(16);
    height: rem(16);
    cursor: pointer;
  }

  &__warning-close::before,
  &__warning-close::after {
    content: '';
    position: absolute;
    top: rem(8);
    left: 0;
    width: rem(14);
    height: rem(2);
    background-color: $yellow;
  }

  &__warning-close::before {
    transform: rotate(45deg);
  }

  &__warning-close::after {
    transform: rotate(-45deg);
  }

  &__expiration-date {
    ::v-deep .form-input__input {
      -moz-appearance: textfield;
    }

    ::v-deep .form-input__label {
      white-space: nowrap;
    }
  }
}

.card-form-agreement {
  margin-top: rem(32);
  background: #f5f5f5;
  border-radius: rem(6);
  padding: rem(16) rem(14) rem(16) rem(16);
}
</style>
