<template>
  <Modal
    size="sm"
    modal-style="loader"
    :show="show"
    non-closable
    @close="$emit('close')"
  >
    <div class="payment-redirect-popup__inner">
      <i class="payment-redirect-popup__icon icon icon-alert" />
      <div class="payment-redirect-popup__title">
        {{ $t('transaction-flow.popup.verification.title') }}
      </div>
      <div class="payment-redirect-popup__msg">
        {{ $t('transaction-flow.popup.verification.message') }}
      </div>
      <div class="payment-redirect-popup__submit-row is-centered">
        <div class="payment-redirect-popup__center-item">
          <button
            class="btn-back-link btn-back-link--mob-short"
            style="color: #000000"
            @click="$emit('close')"
          >
            {{ $t('shared.navigation.button.ok') }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/v1/packages/common/components/modal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'VerificationFailedPopup',

  components: {
    Modal,
  },

  computed: {
    ...mapGetters(['isLocked']),
  },

  props: {
    show: { type: Boolean, required: false, default: false },
  },
};
</script>

<style scoped lang="scss">
.payment-redirect-popup {
  &__submit-row {
    margin-top: rem(36);
    position: relative;
  }

  &__submit-row.is-centered {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    font-size: rem(40);
    line-height: rem(24);
    margin: auto;
    padding-bottom: rem(32);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(34) rem(32) rem(32) rem(32);
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(24);
    text-align: center;
  }

  &__msg {
    margin-top: rem(4);
    text-align: center;
    font-size: rem(14);
    line-height: rem(18);
  }

  &__submit {
    position: relative;
    margin-top: rem(32);
    display: flex;
    justify-content: center;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
