import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useTransactionFlow } from './useTransactionFlow';
import { cancelPendingRequests } from '@/v1/packages/common/services/api';
import Storage from '@/v1/packages/common/services/storage';

const PAYMENT_DETAILS = 'payment-details';
const PAYOUT_DETAILS = 'payout-details';

const isConfirmationVisible = ref(false);

const reset = async (store, invoice) => {
  await cancelPendingRequests();
  store.commit('request/request.setQuoteId', null, { root: true });
  store.commit('request/request.setInvoiceId', invoice, { root: true });
  store.commit('transaction/setInvoice', null, { root: true });
  store.commit('store.setIsRequestWithQuote', false, { root: true });
  Storage.remove(`selectedCardId:${invoice}`);
  store.commit('card/setCardsLoaded', false, { root: true });
  store.commit('card/resetAddCard', {}, { root: true });
  store.commit('v2/paymentMethods/setPaymentMethod', {}, { root: true });
  store.commit('v2/paymentMethods/setPayoutMethod', {}, { root: true });
  await store.dispatch('exchangeForm/deleteQuote', {}, { root: true });
  await store.dispatch('exchangeForm/init', store.getters['request/requestId']);
  await store.dispatch('v2/paymentMethods/getMethods', true, { root: true });
}

export function useBackButton() {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const { isBuyCryptoFlow, isSellCryptoFlow, isSwapCryptoFlow } = useTransactionFlow();
  const hasWallet = store.getters['request/hasWallet'];

  const isBackButtonAvailable = () => {
    if (isSwapCryptoFlow.value) {
      return false;
    }

    const goBackFeatureFlag = store.getters['feature/goBack'];
    const isOneClickFlow = store.getters['isOneClickFlow'];
    const { name: routeName } = route;
    if (isSellCryptoFlow.value) {
      if (routeName === PAYMENT_DETAILS) {
        // XXX: DO NOT show back button in sell crypto on payment details screen
        return false;
      }

      if (isOneClickFlow) {
        return goBackFeatureFlag.isEnabled() && routeName === PAYMENT_DETAILS;
      }

      return goBackFeatureFlag.isEnabled();
    }

    if ((routeName === PAYOUT_DETAILS && isOneClickFlow)) {
      return false;
    }

    return goBackFeatureFlag.isEnabled() && !(isOneClickFlow && hasWallet);
  }

  const handleBackButton = async () => {
    if (store.getters['paymentErrorPopup/showPaymentErrorCallout']) {
      store.dispatch('paymentErrorPopup/setShowPaymentErrorCallout', false);
    }

    if (isSwapCryptoFlow.value) {
      return Promise.reject(new Error('Not allowed'));
    }

    const { name: routeName } = route;
    if (isSellCryptoFlow.value && routeName === PAYMENT_DETAILS && !isConfirmationVisible.value) {
      toggleConfirmationModal();

      return;
    }

    if (isConfirmationVisible.value) {
      isConfirmationVisible.value = false;
    }

    let path = '/v2/exchange-form';
    if (isBuyCryptoFlow.value && routeName === PAYMENT_DETAILS && !hasWallet) {
      path = '/transaction-flow/payout-details';
    }

    const invoice = store.getters['external/funnelInvoiceId'];
    if (invoice && path === '/v2/exchange-form') {
      await reset(store, invoice);

      await router.replace({ path });
      await store.dispatch('quote/reset', {}, { root: true });
      store.commit('transaction/setPayment', {}, { root: true });
      store.commit('transaction/setPayout', {}, { root: true });

      return;
    }

    await router.replace({ path });
  };

  const toggleConfirmationModal = () => {
    isConfirmationVisible.value = !isConfirmationVisible.value;
  };

  return {
    isBackButtonAvailable,
    handleBackButton,
    isConfirmationVisible,
    toggleConfirmationModal,
  }
}
