import { getNumbersFromString } from '@/v1/packages/common/services/util';

export default class CryptoViewModel {
  constructor(crypto) {
    this.crypto = crypto;
  }

  getDepositAmount() {
    return getNumbersFromString(this.crypto.getDepositAmount());
  }

  getAddressLink() {
    return this.crypto.getAddressLink();
  }

  hasAddress() {
    return this.getAddress() !== undefined;
  }

  getAddress() {
    return this.crypto.getAddress();
  }

  getExplorerLink() {
    return this.crypto.getExplorerLink();
  }

  getOurWalletLink() {
    return this.crypto.getOurWalletLink();
  }

  getConfirmations() {
    return this.crypto.getConfirmations();
  }
}
