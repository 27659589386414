<template>
  <span>
    <Title v-if="!showCoolOffTimer">{{ $t('transaction-flow.steps.extra-verification.title') }}</Title>
    <CoolOffPeriodWaiting
      v-if="showCoolOffTimer"
      :cool-off-time-left="coolOffTimer"
    />
    <template v-else-if="showSumsubSdk && sumsubConfig.accessToken">
      <div class="sumsub-websdk-wrapper">
        <SumsubWebsdk
          :api-url="sumsubConfig.apiUrl"
          :flow-name="sumsubConfig.flowName"
          :access-token="sumsubConfig.accessToken"
          :email="sumsubConfig.email"
          :language="language"
          :new-token-callback="newTokenCallback"
          @actionStatusCompleted="onActionStatusCompleted"
          @error="onError"
          @step="onStep"
          @message="onMessage"
        />
      </div>
    </template>
    <template v-else-if="showPendingMessage">
      <!-- eslint-disable-next-line -->
      <Paragraph v-html="$t('transaction-flow.steps.extra-verification.review-subtitle')" />
      <div class="verification-form-approve__icon">
        <img
          :src="iconUrl"
          alt="icon"
        >
      </div>
      <Loader class="mt-32 mb-32 ml-auto mr-auto" />
      <Paragraph>{{
        $t('transaction-flow.steps.extra-verification.review-text')
      }}</Paragraph>
    </template>
  </span>
</template>

<script>
import { nextTick } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/v1/packages/common/components/loader.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import SumsubWebsdk from '@/v1/packages/common/components/sumsub-websdk.vue';
import Title from '@/v1/packages/common/components/title.vue';
import { errorHandler as SumsubWidgetErrorHandler } from '@/v1/packages/common/services/sumsub';
import { STEP_ERROR } from '@/v1/packages/transaction/models/steps';

import { setRoutePreloadState } from '@/v1/router/PreloadRouterView';
import { setVerificationAsLoaded } from '@/services/waitForVerification';
import CoolOffPeriodWaiting from '@/v1/packages/transaction/components/cool-off-period-waiting.vue';
import iconUrl from '@/v1/packages/common/assets/images/verification-additional-icon.png?url';

const COOL_OFF_TIMER_EXPIRED = '00:00';

export default {
  components: {
    Title,
    Paragraph,
    Loader,
    SumsubWebsdk,
    CoolOffPeriodWaiting,
  },

  data: () => ({
    iconUrl,
    isSumsubActionCompleted: false,
    isSumSubWidgetLoaded: false,
  }),

  computed: {
    ...mapGetters('extraVerificationFields', ['fields']),
    ...mapGetters('sumsubExtraVerification', ['sumsubConfig']),
    ...mapGetters(['language']),
    ...mapGetters('transaction', ['extraVerification']),

    sumsubActionField() {
      return this.fields.find(field => field.isSumSubAction());
    },
    showSumsubSdk() {
      return (
        (this.extraVerification.isPending()
          || this.extraVerification.isRejected())
        && !this.isSumsubActionCompleted
      );
    },
    showPendingMessage() {
      return this.extraVerification.isReview() || this.isSumsubActionCompleted;
    },
    showCoolOffTimer() {
      return this.coolOffTimer && this.coolOffTimer !== COOL_OFF_TIMER_EXPIRED;
    },
    coolOffTimer() {
      if (!this.sumsubActionField || !this.sumsubActionField.hasCoolOffTimer()) {
        return COOL_OFF_TIMER_EXPIRED;
      }

      return this.sumsubActionField.getCoolingOffFinishIn();
    },
  },

  watch: {
    extraVerification(
      changedExtraVerification,
      oldExtraVerification,
    ) {
      if (
        !oldExtraVerification.isRejected()
        && changedExtraVerification.isRejected()
      ) {
        this.isSumsubActionCompleted = true; // if verification was just rejected, we cannot show WebSDK yet, need to load new config first
      }

      if (
        this.isSumsubActionCompleted
        && (changedExtraVerification.isPending()
          || changedExtraVerification.isRejected())
      ) {
        this.getConfig(this.sumsubActionField.id).then(() => {
          this.isSumsubActionCompleted = false;
        });
      }
    },
  },

  mounted() {
    this.getConfig(this.sumsubActionField.id);
  },

  methods: {
    ...mapActions('sumsubExtraVerification', ['getConfig']),

    notifySumSubWidgetLoaded() {
      // notify preloader view only once
      if (this.isSumSubWidgetLoaded) return;

      setVerificationAsLoaded();

      // it takes some time to display all elements on the sumsub widget
      nextTick(() => {
        setRoutePreloadState(this.$options.name, true);
        window.scrollTo(0, 0);
      });
      this.isSumSubWidgetLoaded = true;
    },

    onActionStatusCompleted() {
      this.isSumsubActionCompleted = true;
    },
    onStep() {
      this.notifySumSubWidgetLoaded();
    },
    onMessage(type) {
      // When verification is pending, sumsub not emit `step` message
      // Closest is `applicantStatus` that emits when applicant data is loaded
      if (type === 'idCheck.applicantStatus') {
        if (this.verification.isRejected() || this.verification.isUnverified()) {
          this.notifySumSubWidgetLoaded();
        }
      }
    },
    onError(error) {
      const handleResult = SumsubWidgetErrorHandler.handle(
        error,
        this.sumsubConfig.email,
      );
      if (handleResult.isFatal()) {
        this.$router.push({ name: STEP_ERROR });
      }
    },
    newTokenCallback() {
      return this.getConfig(this.sumsubActionField.id).then(() => this.sumsubConfig.accessToken);
    },
  },
};
</script>

<style scoped lang="scss">
.verification-form-approve {
  &__icon {
    margin: rem(4) 0 rem(24);
  }

  ::v-deep &__icon img {
    display: block;
    max-width: rem(100);
    margin: auto;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__icon {
      margin: rem(40) 0 rem(24);
    }
  }
}

.sumsub-websdk-wrapper {
  padding: 2.5em 0 1.5em 0;
}
</style>
