<template>
  <div>
    <HeaderTitle
      :is-back-button-available="isBackButtonAvailable"
      @back="handleBackButton"
    >
      {{ $t('transaction-flow.steps.payment-details.title') }}
    </HeaderTitle>
    <Paragraph>{{ $t('transaction-flow.steps.payment-details.paragraph-bank') }}</Paragraph>
    <FormRow>
      <BankDetails
        :title="$t('transaction-flow.steps.payment-details.bank-details.title').toString()"
        :comment="bankDetailsComment"
      >
        <template v-for="(item, index) in items">
          <CopyableItem
            v-if="item.title"
            :key="index"
            :label="$t(item.title).toString()"
            :placeholder="item.placeholder"
            :value="item.data"
          />
          <template v-else>
            <div
              v-if="item.subtitle"
              :key="index + 'title'"
              class="sales-funnel-bank-details__subtitle"
            >
              <b>{{ $t(item.subtitle) }}:</b>
            </div>
            <div
              v-for="childItem in item.row"
              :key="childItem"
            >
              <CopyableItem
                :label="$t(childItem.title).toString()"
                :placeholder="childItem.placeholder"
                :value="childItem.data"
              />
            </div>
          </template>
          <BankDetailsWarning
            v-if="item.warning"
            :key="index + 'warning'"
            :message="item.warning"
          />
        </template>
      </BankDetails>
    </FormRow>
    <Actions>
      <template
        v-if="hasNext"
        #submit
      >
        <button
          v-if="isPaymentDetails"
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="markAsPaid"
        >
          {{ $t('shared.navigation.button.payment-made') }}
        </button>
        <button
          v-if="!isPaymentDetails"
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="navigate"
        >
          {{ $t('shared.navigation.button.continue') }}
        </button>
      </template>
    </Actions>
  </div>
</template>

<script>
import { HeaderTitle } from '@/v1/overrides/AuthFlow/exports';
import FormRow from '@/v1/packages/common/components/form/form-row.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

import CopyableItem from '@/v1/packages/common/components/copyable-item.vue';
import Actions from './actions.vue';
import BankDetails from './bank-details.vue';
import { createBankDetailsList, createBankSwiftDetailsList } from '../factories/BankDetailsFactory';

import eventBus from '../helpers/eventBus';
import BankDetailsWarning from './bank-details-warning.vue';
import { useBackButton } from '@/v1/composables/useBackButton';

export default {
  components: {
    HeaderTitle,
    Paragraph,
    FormRow,
    BankDetails,
    Actions,
    CopyableItem,
    BankDetailsWarning,
  },
  data() {
    return {
      handleBackButton: () => ({}),
    }
  },
  props: {
    details: { type: Object, required: true },
    isPaymentDetails: { type: Boolean, required: true, default: false },
  },
  computed: {
    ...mapGetters('transaction/navigation', ['hasNext', 'next']),
    ...mapState('quote', ['payment']),
    ...mapGetters(['isLocked']),
    ...mapGetters('transaction', { transactionPayment: 'payment' }),
    ...mapGetters(['isCoinmamaTheme']),
    isBackButtonAvailable() {
      const { isBackButtonAvailable } = useBackButton();

      return isBackButtonAvailable();
    },
    paymentCurrency() {
      return this.payment.amount.getCurrency();
    },
    items() {
      const paymentDetails = this.details;
      if (this.isCoinmamaTheme) {
        paymentDetails.comment = paymentDetails.comment.replace('Transaction Nr.', 'CMAMA');
      }

      if (this.transactionPayment.isBankSwift()){
        return createBankSwiftDetailsList(this.paymentCurrency, paymentDetails);
      }

      return createBankDetailsList(this.paymentCurrency, paymentDetails);
    },
    bankDetailsComment() {
      if (this.transactionPayment.isBankSwift()){
        return this.$t('transaction-flow.steps.payment-details.bank-swift-details.comment').toString();
      }

      return this.$t('transaction-flow.steps.payment-details.bank-details.comment').toString();
    },
  },
  created() {
    eventBus.emit('transaction-flow.payment-details.loaded');
  },
  mounted() {
    const { handleBackButton } = useBackButton();
    this.handleBackButton = handleBackButton;
  },
  methods: {
    ...mapActions('transaction', ['markTransactionAsPaid']),
    ...mapMutations(['lock', 'unlock']),
    markAsPaid() {
      this.lock();
      this.markTransactionAsPaid()
        .then(() => {
          this.navigate();
        })
        .finally(() => {
          this.unlock();
        });
    },
    navigate() {
      this.$router.push({ name: this.next.name });
    },
  },
};
</script>
