<template>
  <span>
    <Pictogram
      :src="pictogramUrl"
    />
    <Paragraph :bold="true">
      {{
        $t(
          'transaction-flow.steps.payment-details.payment-status-waiting-screen.paragraph',
        )
      }}
    </Paragraph>
    <Loader class="mt-32 mb-32 ml-auto mr-auto" />
  </span>
</template>

<script>
import Loader from '@/v1/packages/common/components/loader.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Pictogram from '@/v1/packages/common/components/pictogram.vue';
import pictogramUrl from '@/v1/packages/common/assets/images/verification-process.svg?url';

export default {
  name: 'PaymentStatusWaiting',

  components: {
    Pictogram,
    Paragraph,
    Loader,
  },

  data: () => ({
    pictogramUrl,
  }),
};
</script>
