<template>
  <div class="trustly-payment-details-new">
    <Title
      data-testid="payment-title"
      class="card-payment-drawer__title"
    >
      {{ $t('transaction-flow.steps.trustly-payment-details.select_bank') }}
    </Title>
    <div class="loading">
      <div
        v-if="isLoading"
        class="loading__icon"
      />
    </div>
    <TrustlyWidget
      v-show="!isLoading"
      :trustly-inst="trustlyInst"
      @close="onClose"
      @ready="onReady"
    />
  </div>
</template>

<script setup>
import TrustlyService from '../services/trustlyInitService';
import { ref, defineProps } from 'vue';
import store from '@/v1/store';
import Title from '@/v1/packages/common/components/title.vue';
import TrustlyWidget from './TrustlyWidget/trustly-widget.vue';

store.commit('transaction/stopPollingTransaction');

const isLoading = ref(true);

const props = defineProps({
  trustlyInst: {
    type: TrustlyService,
    required: true,
  },
});

function onReady() {
  isLoading.value = false;
}

function onClose() {
  isLoading.value = true;
}
</script>

<style lang="scss" scoped>
.trustly-payment-details-new {
  position: relative;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  &__icon {
    @include spinerIcon;
    height: 100%;
    padding: 6px;

    &:after {
      width: 40px;
      height: 40px;

      @media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
        width: calcVh(40, $widget-h-sm);
        height: calcVh(40, $widget-h-sm);
      }

      @media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
        width: calcVh(40, $widget-h-xlg);
        height: calcVh(40, $widget-h-xlg);
      }

      @media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
        width: calcVh(40, $widget-h-xxlg);
        height: calcVh(40, $widget-h-xxlg);
      }
    }
  }
}
</style>
