<template>
  <Title data-testid="payment-title">
    {{ $t('transaction-flow.payment-details.3ds-waiting-screen.title') }}
  </Title>

  <div class="local-header">
    <div class="local-header__loader" />
  </div>

  <p class="info">
    {{ $t('widget.transaction-flow.payment-details.3ds-waiting-screen.info') }}
  </p>
</template>

<script>
import Title from '@/v1/packages/common/components/title.vue';

export default {
  name: 'ChallengeWaiting',
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
.local-header {
  text-align: center;
  padding: 48px 0;
  font-weight: 700;

  @media (max-width: $tablet-max) {
    padding-top: 32px;
  }

  &__loader {
    @include spinerIcon;

    margin: 0 auto;
  }
}

.info {
  text-align: center;
  max-width: rem(416);
  margin: auto;
  font-size: rem(16);
  line-height: rem(22);
  padding-bottom: 20px;
}
</style>
