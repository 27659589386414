<template>
  <div class="apm-payment-error">
    <div class="local-header">
      <i class="svg-icon icon-failed" />
      {{ $t('transaction-flow.steps.apm-payment-error.title') }}
    </div>

    <div class="local-footer">
      <p class="local-footer-info">
        {{ $t('transaction-flow.steps.apm-payment-error.message') }}
      </p>

      <div class="buttons-group">
        <CloseWidgetButton
          mode="view"
          @click="closeWidget"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { emitToParent, STATES } from '@/services/widgetApiEvents';
import CloseWidgetButton from '@/v1/components/CloseWidgetButton';

export default defineComponent({
  name: 'ApmPaymentError',
  components: { CloseWidgetButton },
  methods: {
    closeWidget() {
      emitToParent('state', {
        state: STATES.STATE_REJECTED,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/v1/assets/scss/blocks/buttons-group";

.apm-payment-error {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.local {
  &-header {
    text-align: center;
    margin-bottom: -20px;

    font-size: rem(28);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 16px;

    @media (max-width: $tablet-max) {
      margin-bottom: 12px;
    }
  }

  &-body {
    text-align: center;
    margin-bottom: rem(146);

    @media (max-width: $tablet-max) {
      margin-bottom: 40px;
    }
  }

  &-footer {
    text-align: center;

    margin-top: 0;

    display: flex;
    flex-direction: column;
    flex-grow: 2;
  }

  &-footer-info {
    max-width: rem(370);
    text-align: center;
    margin: 0 auto rem(24) auto;

    @media (max-width: $tablet-max) {
      width: auto;
    }
  }
}

.svg-icon {
  display: block;
  margin: rem(20) auto rem(24) auto;
  width: rem(96);
  height: rem(96);

  @media (max-width: $tablet-max) {
    width: 80px;
    height: 80px;
  }
}

.btn-link {
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &:hover {
    border-color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: $laptop-min) and (max-height: $widget-height-sm) {
  .local {
    &-header {
      font-size: 3.6vh;
      line-height: 4.16vh;
      margin-bottom: 4vh;
    }

    &-body {
      margin-bottom: calcVh(90, $widget-h-sm);
    }

    &-footer-info {
      font-size: calcVh(16, $widget-h-sm);
      line-height: calcVh(20, $widget-h-sm);
      padding-bottom: calcVh(24, $widget-h-sm);
      margin: auto;
    }
  }

  .svg-icon {
    margin: calcVh(20, $widget-h-sm) auto calcVh(24, $widget-h-sm) auto;
    width: calcVh(96, $widget-h-sm);
    height: calcVh(96, $widget-h-sm);
  }
}

@media (min-width: $desktop-xlg-min) and (max-height: $widget-height-xlg) {
  .local {
    &-header {
      font-size: 3.7vh;
      line-height: 4.18vh;
      margin-bottom: 7.31vh;
    }
  }
}

@media (min-width: $desktop-xxlg-min) and (max-height: $widget-height-xxlg) {
  .local {
    &-header {
      font-size: 3.71vh;
      line-height: 4.24vh;
      margin-bottom: 7.42vh;
    }
  }
}

</style>
