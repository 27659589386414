<template>
  <HiddenTotalFeesDetails
    v-if="isTotalFeesHidden"
    :service-fees="serviceFees"
    :network-fees="networkFees"
    :total-fees="totalFees"
    :is-expanded="isExpanded"
    :is-quote-has-error="isQuoteHasError"
    @expand="toggleFeesDropdown"
  />

  <RegularFeesDetails
    v-else
    :service-fees="serviceFees"
    :network-fees="networkFees"
    :total-fees="totalFees"
    :is-expanded="isExpanded"
    :is-quote-has-error="isQuoteHasError"
    @expand="toggleFeesDropdown"
  />
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import RegularFeesDetails from '@/v1/components/FeesDetails/Components/RegularFeesDetails.vue';
import HiddenTotalFeesDetails from '@/v1/components/FeesDetails/Components/HiddenTotalFeesDetails.vue';

export default {
  name: 'FeesDetails',

  components: {
    HiddenTotalFeesDetails,
    RegularFeesDetails,
  },

  props: {
    fees: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const store = useStore();
    const isExpanded = ref(false);

    const toggleFeesDropdown = () => {
      isExpanded.value = !isExpanded.value;
    };

    const isSellCryptoFlow = computed(() => store.getters.isSellCryptoFlow);
    const isTotalFeesHidden = computed(() => store.getters.isCoinmamaTheme);
    const isQuoteHasError = computed(() => store.getters['v2/widgetQuote/hasError']);
    const currentCurrencyTo = computed(() => {
      let currency = store.getters['widgetQuote/currencyTo'];
      if (!store.getters['widgetQuote/isQuoteReady']) {
        currency = store.getters['exchangeForm/toCurrencyCode'];
      }

      return store.getters['exchangeForm/getCurrencyBy'](currency);
    });

    const feesCurrency = computed(() => {
      if (props.fees.currency) {
        return props.fees.currency;
      }

      if (isSellCryptoFlow.value) {
        return currentCurrencyTo.value;
      }

      return store.getters['exchangeForm/fromCurrency'];
    });

    const totalFees = computed(() => `${props.fees.total_fee} ${feesCurrency.value}`);
    const serviceFees = computed(() => `${props.fees.service_fee} ${feesCurrency.value}`);
    const networkFees = computed(() => `${props.fees.network_fee} ${feesCurrency.value}`);

    return {
      isExpanded,
      isQuoteHasError,
      isTotalFeesHidden,

      totalFees,
      serviceFees,
      networkFees,

      toggleFeesDropdown,
    };
  },
};
</script>
