<template>
  <span class="bank-payment-waiting">
    <Title>{{ $t('transaction-flow.steps.payment-waiting.bank.title') }}</Title>
    <Paragraph>{{ waitingTimeLabel }}</Paragraph>
    <FunnelLoader />
    <Paragraph>{{
      $t('transaction-flow.steps.payment-waiting.bank.note')
    }}</Paragraph>
  </span>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Title from '@/v1/packages/common/components/title.vue';
import FunnelLoader from './funnel-loader.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FunnelLoader,
    Title,
    Paragraph,
  },
  props: {
    currency: { type: String, required: true },
    paymentSlug: { type: String, required: true },
  },
  data() {
    const { t, te } = useI18n();

    return {
      t,
      te,
    };
  },
  computed: {
    waitingTimeLabel() {
      const currency = this.currency.toLowerCase();
      let key = `transaction-flow.money-service.${this.paymentSlug}.payment-waiting.bank.waiting-time.${currency}`;
      if (!this.te(key)) {
        key = `transaction-flow.steps.payment-waiting.bank.waiting-time.${currency}`;
      }

      return this.t(key);
    },
  },
};
</script>
