import Api from '../api';
import SupportedAssets from '../../models/web-wallets/supportedAssets';

const apiUrl = `${import.meta.env.VITE_APP_WEB_WALLETS_API_HOST}/public/web-wallets`; // eslint-disable-line no-undef

export default {
  getWallets() {
    return Api()
      .get(`${apiUrl}/v2/wallets`)
      .then(({ data }) => data);
  },

  getWalletsBy(assetId) {
    return Api()
      .get(`${apiUrl}/v2/wallets/${assetId}`)
      .then(({ data }) => data.data);
  },

  addWallet(wallet) {
    return Api().post(`${apiUrl}/v2/wallets`, wallet);
  },

  getSupportedAssets() {
    return Api()
      .get(`${apiUrl}/v1/assets/supported`)
      .then(({ data }) => data.data.map(el => SupportedAssets.createFromData(el)));
  },
};
