// eslint-disable-next-line import/no-extraneous-dependencies
import { TransactionFlowStep } from '@/v1/packages/transaction/publicApi';

export default {
  install(Vue, { store, router }) {
    watchFunnelNavigation(store, router);
  },
};

function watchFunnelNavigation(store, router) {
  store.watch(
    (state, getters) => [
      getters['external/funnelNavigationCurrent'],
      getters['external/funnelTransactionFlowDetails'],
    ],
    ([route = {}], [oldRoute = {}]) => {
      if (route.name === oldRoute.name) {
        return;
      }

      if (
        Object.values(TransactionFlowStep).includes(route.name)
        && router.currentRoute.value.name !== route.name
      ) {
        router.push(route);
      }
    },
  );
}
