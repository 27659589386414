import { getNumbersFromString } from '@/v1/packages/common/services/util';

export const bankDetailsList = (currency, bankDetails) => ({
  iban: {
    title: `transaction-flow.bank-details.iban.${currency.toLowerCase()}`,
    data: bankDetails.getIban(),
    name: 'iban',
  },
  swift: {
    title: 'transaction-flow.bank-details.bank-swift',
    data: bankDetails.getBankSwift(),
    name: 'swift',
  },
  localCode: {
    title: `transaction-flow.bank-details.local_code.${currency.toLowerCase()}`,
    data: bankDetails.getLocalCode(),
    name: 'localCode',
  },
  accountNr: {
    title: `transaction-flow.bank-details.account_nr.${currency.toLowerCase()}`,
    data: bankDetails.getAccountNr(),
    name: 'accountNr',
  },
  receiver: {
    title: 'transaction-flow.bank-details.receiver',
    data: bankDetails.getReceiver(),
    name: 'receiver',
  },
  regNr: {
    title: 'transaction-flow.bank-details.reg-nr',
    data: bankDetails.getRegNr(),
    name: 'regNr',
  },
  beneficiaryAddress: {
    title: 'transaction-flow.bank-details.benef_address',
    data: bankDetails.getBeneficiaryAddress(),
    name: 'beneficiaryAddress',
  },
  bankName: {
    title: 'transaction-flow.bank-details.bank',
    data: bankDetails.getBankName(),
    name: 'bankName',
  },
  bankAddress: {
    title: 'transaction-flow.bank-details.bank-address',
    data: bankDetails.getBankAddress(),
    name: 'bankAddress',
  },
  depositAmount: {
    title: 'transaction-flow.bank-details.deposit-amount',
    data: getNumbersFromString(bankDetails.getDepositAmount()),
    placeholder: bankDetails.getDepositAmount(),
    name: 'depositAmount',
  },
  paymentReference: {
    title: 'transaction-flow.bank-details.comment',
    data: bankDetails.getComment(),
    warning: 'transaction-flow.bank-details.payment-reference-warning',
    name: 'paymentReference',
  },
  beneficiaryAccountNumber: {
    title: 'transaction-flow.bank-details.beneficiary_account_number',
    data: bankDetails.getBeneficiaryAccountNumber(),
    name: 'beneficiaryAccountNumber',
  },
  beneficiaryBankAddress: {
    title: 'transaction-flow.bank-details.beneficiary_bank_address',
    data: bankDetails.getBeneficiaryBankAddress(),
    name: 'beneficiaryBankAddress',
  },
  beneficiaryBankBic: {
    title: 'transaction-flow.bank-details.beneficiary_bank_bic',
    data: bankDetails.getBeneficiaryBankBic(),
    name: 'beneficiaryBankBic',
  },
  beneficiaryBankName: {
    title: 'transaction-flow.bank-details.beneficiary_bank_name',
    data: bankDetails.getBeneficiaryBankName(),
    name: 'beneficiaryBankName',
  },
  beneficiaryName: {
    title: 'transaction-flow.bank-details.beneficiary_name',
    data: bankDetails.getBeneficiaryName(),
    name: 'beneficiaryName',
  },
});
