<template>
  <div class="payment-details">
    <CryptoPaymentDetails
      v-if="payment.isCrypto()"
      :payment="payment"
    />
    <BankPaymentDetails
      v-else-if="isBank"
      :details="payment.getDetails()"
      :is-payment-details="transaction.isPaymentDetails()"
    />
    <ApmPaymentDetails v-else-if="showApmWaiting" />
    <TrustlyPaymentDetails
      v-else-if="payment.isTrustly()"
      :payment="payment"
      :invoice="invoice"
    />
    <CardPaymentDetails
      v-else
      :transaction="transaction"
      :invoice="invoice"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BankPaymentDetails from '../components/bank-payment-details.vue';
import CardPaymentDetails from '../components/card-payment-details.vue';
import CryptoPaymentDetails from '../components/crypto-payment-details.vue';
import TrustlyPaymentDetails from '../components/trustly-payment-details.vue';
import ApmPaymentDetails from '../components/apm-payment-details.vue';

export default {
  components: {
    ApmPaymentDetails,
    BankPaymentDetails,
    CardPaymentDetails,
    CryptoPaymentDetails,
    TrustlyPaymentDetails,
  },
  computed: {
    ...mapGetters('transaction', ['payment', 'invoice', 'transaction', 'apmPayment']),
    showApmWaiting() {
      if (this.payment.isTrustly()) {
        return this.transaction.isPaymentWaiting();
      }
      return this.payment.isApm();
    },
    isBank(){
      return this.payment.isBank() || this.payment.isBankSwift();
    },
  },
};
</script>

<style lang="scss">
.payment-details {
  position: relative;

  .bp-cashier-iframe {
    margin-left: -17px;
    margin-right: -17px;
    width: calc(100% + 34px);
  }

  .bp-cashier-iframe,
  .bp-splash-screen {
    margin-top: -70px !important;

    @media (min-width: 1022px) and (max-width:1137px)  {
      margin-top: -20px !important;
    }

    @media (max-width: 486px)  {
      margin-top: -20px !important;
    }
  }
}
</style>
