<template>
  <div>
    <FunnelLoader />
    <Subtitle>
      {{ $t('transaction-flow.steps.apm-payment-error.title') }}
    </Subtitle>
    <Paragraph
      v-html="$t('transaction-flow.steps.apm-payment-error.message')"
    />
  </div>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Subtitle from '@/v1/packages/common/components/subtitle.vue';
import UrlGenerator from '@/v1/packages/common/services/url';
import { mapGetters, mapMutations } from 'vuex';

import FunnelLoader from './funnel-loader.vue';

export default {
  components: {
    Paragraph,
    Subtitle,
    FunnelLoader,
  },
  computed: {
    ...mapGetters(['isLocked']),
  },
  methods: {
    ...mapMutations(['lock', 'clearLocks']),
    navigate() {
      this.lock();
      window.location = UrlGenerator.generate('/');
    },
  },
  mounted() {
    setTimeout(() => {
      this.clearLocks();
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
