<template>
  <div class="fallback-popup">
    <div
      class="fallback-popup__title"
      v-html="$t('transaction-flow.fallback-modal.title')"
    />
    <div class="fallback-popup__progress">
      <svg viewBox="0 0 80 80">
        <circle
          cx="40"
          cy="40"
          r="38"
        />
        <path
          transform="rotate(-180)"
          d="M 40 78 a 38 38 0 0 1 0 -76 38 38 0 0 1 0 76"
        />
      </svg>
      <span class="fallback-popup__timer">
        {{ modalTimer }}
      </span>
    </div>
    <div
      v-if="cardDeclineMessagesFeatureEnabled"
      class="fallback-popup__message"
    >
      {{ $t(`transaction-flow.card-payment-decline-codes.bridger-pay.${cardPaymentDeclineCode}`) }}
      <br>
      <i18n-t
        keypath="transaction-flow.fallback-modal.message-new"
      >
        <template #link>
          <a
            class="fallback-popup__link"
            @click.prevent="handleRedirectToRelevant"
          >
            {{ $t("transaction-flow.fallback-modal.link-text") }}
          </a>
        </template>
      </i18n-t>
    </div>
    <div
      v-else
      class="fallback-popup__message"
    >
      <i18n-t keypath="transaction-flow.fallback-modal.message">
        <template #break>
          <br>
        </template>
        <template #link>
          <a
            class="fallback-popup__link"
            @click.prevent="handleRedirectToRelevant"
          >
            {{ $t('transaction-flow.fallback-modal.link-text') }}
          </a>
        </template>
      </i18n-t>
    </div>
    <button
      class="fallback-popup__btn btn btn-large"
      @click="handleClosePopup"
    >
      {{ $t('transaction-flow.fallback-modal.button') }}
    </button>
  </div>
</template>
<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import store from '@/v1/store';

const props = defineProps({
  autoFallbackTimer: {
    type: Number,
    required: true,
  },
  show: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(['fallbackRedirect', 'close']);

const modalTimer = ref(props.autoFallbackTimer);
let timeoutId = -1;
const animationTimer = computed(() => `${props.autoFallbackTimer}s`);

const selectedCard = store.getters['card/selectedCard'];
const cardPaymentDeclineCode = computed(() => selectedCard.getDeclineCardErrorCode());
const cardDeclineMessagesFeature = computed(() => store.getters['feature/cardDeclineMessagesFeature']);
const cardDeclineMessagesFeatureEnabled = computed(() => cardDeclineMessagesFeature.value.isEnabled());
const clearTimeoutId = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
};
const handleRedirectToRelevant = () => {
  emits('fallbackRedirect');
  clearTimeoutId();
};
const handleClosePopup = () => {
  emits('close');
  clearTimeoutId();
};

const decreaseTimer = startValue => {
  let count = startValue;
  if (count > 0) {
    modalTimer.value = count--;
    clearTimeoutId();
    timeoutId = window.setTimeout(() => {
      decreaseTimer(count);
    }, 1000);
  }
  if (startValue === 0) {
    modalTimer.value = 0;
    handleRedirectToRelevant();
  }
};

onMounted(() => {
  decreaseTimer(props.autoFallbackTimer);
});

onBeforeUnmount(() => {
  clearTimeoutId();
});

</script>
<style lang="scss" scoped>
.fallback-popup {
  padding: 64px 88px 48px;
  background: #FFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 60vw;
  gap: 16px;

  &__title {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }

  &__message {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__progress {
    position: relative;
    width: 80px;
    height: 80px;
  }

  &__timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 32px;
    font-weight: 600;
    line-height: 35.2;
  }

  &__btn {
    padding: 14px 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    background: transparent;
    border-radius: 10px;
    color: black;
    transition: 0.2s linear;

    &:hover {
      background: black;
      color: white;
    }
  }
  @media screen and (max-width: $mobile-max) {
    min-width: 327px;
    padding: 48px 16px;

    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}
circle {
  fill: none;
  stroke: #F5F5F5;
  stroke-width: 3;
}

path {
  stroke: black;
  stroke-width: 4;
  stroke-dasharray: 238.76;
  fill: none;
  transform-origin: 40px 40px;
  animation:
    pathStroke v-bind(animationTimer) linear;
}

@keyframes pathStroke {
  0% {  stroke-dashoffset: 238.76; }
  100% { stroke-dashoffset: 0; }
}
</style>
