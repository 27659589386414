<template>
  <span>
    <Title>{{ $t('transaction-flow.steps.gift-card.title') }}</Title>
    <Paragraph v-html="$t('transaction-flow.steps.gift-card.subtitle')" />
    <div class="sales-funnel-content">
      <FormInput
        id="gift_card_number"
        v-mask="'NNNN NNNN NNNN NNNN'"
        :value="giftCardNumber"
        name="gift-card-number"
        type="text"
        :label="$t('transaction-flow.steps.gift-card.gift-card-number.label')"
        :has-error="hasError"
        :error="errorMessage"
        :display-uppercase="true"
        @input="onInput"
      />
    </div>
    <Actions>
      <template #submit>
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-primary"
          :class="{ 'is-loading': isLocked }"
          @click="submit()"
        >
          {{ $t('shared.navigation.button.submit') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import FormInput from '@/v1/packages/common/components/form/form-input.vue';
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Title from '@/v1/packages/common/components/title.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions.vue';

export default {
  components: {
    Title,
    Paragraph,
    FormInput,
    Actions,
  },
  data: () => ({
    giftCardNumber: '',
    errorMessage: '',
  }),
  computed: {
    ...mapGetters(['isLocked']),
    hasError() {
      return this.errorMessage !== '';
    },
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    ...mapActions('transaction', ['submitGiftCard']),
    onInput(value) {
      this.errorMessage = '';
      this.giftCardNumber = value.toUpperCase();
    },
    submit() {
      this.lock();
      this.submitGiftCard(this.giftCardNumber)
        .catch(({ data }) => {
          if (data && 'errors' in data) {
            data.errors.forEach(error => {
              if (error.property_path === 'giftCardNumber') {
                this.errorMessage = error.message;
              }
            });
          }
        })
        .finally(() => this.unlock());
    },
  },
};
</script>
