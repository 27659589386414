<template>
  <span>
    <Pictogram
      :src="pictogramUrl"
    />
    <Subtitle>{{
      $t('transaction-flow.steps.payment-error.subtitle')
    }}</Subtitle>
    <Paragraph v-html="$t('transaction-flow.steps.payment-error.message')" />
    <Actions>
      <template #submit>
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="navigate()"
        >
          {{ $t('shared.navigation.button.go-to-main-page') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Pictogram from '@/v1/packages/common/components/pictogram.vue';
import Subtitle from '@/v1/packages/common/components/subtitle.vue';
import UrlGenerator from '@/v1/packages/common/services/url';
import { mapGetters, mapMutations } from 'vuex';
import pictogramUrl from '@/v1/packages/common/assets/images/verification-block.svg?url';

import Actions from '../components/actions.vue';

export default {
  components: {
    Subtitle,
    Paragraph,
    Pictogram,
    Actions,
  },
  data: () => ({
    pictogramUrl,
  }),
  computed: {
    ...mapGetters(['isLocked']),
  },
  methods: {
    ...mapMutations(['lock', 'clearLocks']),
    navigate() {
      this.lock();
      window.location = UrlGenerator.generate('/');
    },
  },
  mounted() {
    setTimeout(() => {
      this.clearLocks();
    });
  },
};
</script>
