<template>
  <span>
    <Title>{{
      $t('transaction-flow.steps.card-authorization.failed.title')
    }}</Title>
    <Paragraph>{{ error }}</Paragraph>
  </span>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Title from '@/v1/packages/common/components/title.vue';

export default {

  components: {
    Title,
    Paragraph,
  },
  props: {
    error: { type: String, required: true },
  },
};
</script>
