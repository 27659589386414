<template>
  <div class="payment-details">
    <Title class="payment-details__title">
      {{ t('widget.transaction.history.make-a-payment') }}
    </Title>
    <section class="payment-details__container">
      <span class="payment-details__item-name payment-details__left-column">{{ t('transaction-flow.steps.payment-details.invoice') }}</span>
      <span class="payment-details__item-info payment-details__left-column">{{ funnelInvoiceId }}</span>
      <span class="payment-details__item-name payment-details__right-column mobile-title">{{ t('transaction-flow.steps.payment-details.sending-to') }}</span>
      <span class="payment-details__item-info payment-details__right-column mobile-info">
        <Icon
          icon="wallet"
          class="payment-details__wallet-icon"
        />
        {{ merchantCompanyName }}
      </span>

      <Paragraph
        v-if="salesItemDescription"
        class="paying-for"
      >
        <i18n-t
          keypath="transaction-flow.steps.payment-details.sales-item"
          tag="div"
          class="paying-for__sales-item"
        >
          <template #salesItemDescription>
            <span class="paying-for__description">
              {{ salesItemDescription }}
            </span>
          </template>
        </i18n-t>
      </Paragraph>

      <div class="payment-details__pay-amount">
        <div class="payment-details__pay-label">
          {{ t('transaction-flow.steps.payment-details.pay-amount') }}
        </div>
        <CopyableItemCryptoAcquiring
          class="payment-details__amount"
          :value="crypto.getDepositAmount()"
          :placeholder="amountPlaceholder"
          :loading="loading"
        />
        <div
          v-if="calculatedEquivalentAmount"
          class="payment-details__equivalent-amount"
        >
          {{ equivalentAmount }}
        </div>
        <Loader
          v-else
          class="payment-details__equivalent-loader"
          :size="'17px'"
        />
      </div>
      <div class="payment-details__left-column">
        <Icon
          icon="phone"
          class="payment-details__phone-icon"
        />
        {{ t('transaction-flow.manual.scan-qr', {
          moneyService,
          cryptoCode,
        }) }}
      </div>
      <div class="payment-details__right-column">
        <QrcodeVue
          v-if="!loading && crypto.getAddress()"
          class="payment-details__qr-code"
          :value="crypto.getAddress()"
        />
        <Loader
          v-else
          :size="'36px'"
        />
      </div>
      <div class="payment-details__payment-to">
        {{ t('transaction-flow.steps.payment-details.manual-payment') }}
      </div>
      <CopyableItem
        class="payment-details__wallet-address"
        :value="crypto.getAddress()"
        :loading="loading"
      >
        <Icon icon="qr-code" />
      </CopyableItem>

      <Paragraph class="payment-details__waiting">
        <CryptoAcquiringLoader class="payment-details__loader" />
        {{ t('transaction-flow.steps.payment-details.scanning') }}
      </Paragraph>
    </section>
    <div class="payment-details__next-step">
      {{ t('transaction-flow.steps.payment-details.paragraph3') }}
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  onMounted,
  onBeforeMount,
  watch,
} from 'vue';
import store from '@/v1/store';
import QrcodeVue from 'qrcode.vue';
import { useI18n } from 'vue-i18n';

import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Title from '@/v1/packages/common/components/title.vue';
import CopyableItemCryptoAcquiring from '@/v1/packages/common/components/copyable-item-crypto-acquiring.vue';
import CopyableItem from '@/v1/packages/common/components/copyable-item.vue';
import Icon from '@/v1/packages/common/components/icon.vue';
import CryptoViewModel from '@/v1/packages/transaction/view-models/crypto';
import CryptoAcquiringLoader from '@/v1/packages/common/components/crypto-acquiring-loader.vue';
import Loader from '@/v1/components/Loader';
import { getExchangeRate } from '@/v1/packages/common/services/clients/seo-prices';
import eventBus from '@/v1/packages/transaction/helpers/eventBus';

const { t } = useI18n();

const calculatedEquivalentAmount = ref(null);
const ratesCalculated = ref(false);

onBeforeMount(() => eventBus.emit('transaction-flow.payment-details.loaded'));
onMounted(() => calculateFiatEquivalent());

const salesItemDescription = computed(() => store.getters['request/salesItemDescription']);
const defaultCurrency = computed(() => store.getters['request/defaultCurrency']);
const payment = computed(() => store.getters['transaction/payment']);
const funnelInvoiceId = computed(() => store.getters['external/funnelInvoiceId']);
const secondsLeft = computed(() => store.getters['cryptoTimer/secondsLeft']);

const moneyService = computed(() => payment.value.name);
const crypto = computed(() => new CryptoViewModel(payment.value.getDetails()));
const loading = computed(() => !crypto.value.hasAddress());
const cryptoCode = computed(() => payment.value.amount.currency);
const amountPlaceholder = computed(() => `${crypto.value.getDepositAmount()} ${cryptoCode.value}`);
const nativeCurrency = computed(() => defaultCurrency.value ?? 'USD');
const equivalentAmount = computed(() => `${calculatedEquivalentAmount.value} ${nativeCurrency.value}`);
const merchantCompanyName = computed(() => store.getters['request/merchantCompanyName']);
const calculateFiatEquivalent = async () => {
  try {
    const rate = await getExchangeRate(cryptoCode.value, nativeCurrency.value);
    calculatedEquivalentAmount.value = (crypto.value.getDepositAmount() * rate).toFixed(2);
    ratesCalculated.value = true;
  } catch (e) {
    ratesCalculated.value = false;
  }
};

watch(secondsLeft.value, (oldTimer, newTimer) => {
  if (newTimer > 0 && newTimer < 1) {
    calculateFiatEquivalent();
  }
});
</script>

<style lang="scss" scoped>
.payment-details {
  &__left-column {
    grid-column: 2;
  }

  &__right-column {
    grid-column: 3;
  }

  &__title {
    margin: 0;
    padding-bottom: 24px;
  }

  &__container {
    display: grid;
    grid-template-columns: 14px minmax(0, 1fr) minmax(0, 1fr) 14px;
    width: 100%;
    border-bottom: 1px solid $grey-06;
    border-top: 1px solid $grey-06;
    padding: 24px 0 32px;
    margin-bottom: 24px;
  }

  &__item-name {
    font-size: 14px;
    color: $black-50;
    margin-bottom: 2px;
  }

  &__pay-label {
    @extend .payment-details__item-name;
    text-align: center;
    margin-bottom: 8px;
  }

  &__item-info {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;

    @media (max-width: 520px)  {
      white-space: nowrap;
    }
  }

  &__pay-amount {
    grid-column: 1 / -1;
    width: 100%;
    padding: 32px 0;
    background-color: $grey-04;
    border-radius: 16px;
    margin-bottom: 24px;
  }

  &__price {
    font-size: 40px;
    font-weight: 600;
    margin: 8px 0;
  }

  &__price-equivalent {
    font-size: 14px;
  }

  &__waiting {
    grid-column: 2 / -2;
    background-color: rgba(97, 102, 190, 0.1);
    color: rgba(97, 102, 190, 1);
    width: max-content;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 0 auto;
  }

  &__loader {
    display: inline-block;
    min-height: 13px;
    min-width: 13px;
    margin-right: 10px;
  }

  &__next-step {
    color: $black-50;
    font-size: 14px;
    text-align: center;
  }

  &__amount {
    width: fit-content;
    margin: 0 auto 8px;
  }

  &__equivalent-amount {
    text-align: center;
    font-size: 14px;
  }

  &__phone-icon {
    display: block;
    margin-bottom: 16px;
  }

  &__qr-code {
    margin: 0 0 0 auto;
    display: block;
  }

  &__payment-to {
    grid-column: 2 / -2;
    margin: 24px 0 8px;
    color: $black-50;
    font-size: 14px;
  }

  &__wallet-address {
    border: 2px solid $grey-05;
    padding: 14px 16px;
    border-radius: 6px;
    grid-column: 2 / -2;
    margin-bottom: 24px;
  }

  &__wallet-icon {
    margin-right: 4px;
  }

  &__equivalent-loader {
    height: min-content;
  }
}

.paying-for {
  grid-column: 2 / -2;
  margin: 0 0 24px;

  &__sales-item {
    color: $black-50;
    text-align: left;
    font-size: 14px;
  }

  &__description {
    color: $black;
  }
}

.payment-details__container .mobile-title {
  grid-row: 1;
  @media (max-width: 520px)  {
    grid-row: unset;
    grid-column: 2;
  }
}

.payment-details__container .mobile-info {
  @media (max-width: 520px)  {
    grid-column: 2;
  }
}
</style>
