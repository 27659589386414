import { UAParser } from 'ua-parser-js';
import { ERROR_CODES } from '@/constants/errorCodes';

export const ignoredStatusCodes = [400, 401, 403, 404, 422, 425, 429];

export const ignoredErrorMessages = [
  'ReportingObserver [deprecation]',
  'Request aborted',
  'Non-Error promise rejection captured',
  'timeout of 30000ms exceeded',
  'Network Error',
  'Request failed with empty response',
  'Load failed', // XXX: Ignore iOS cryptic failed fetch response, as we don't get any more details on why the request failed
  'undefined',
  'Blocked a frame with origin',
  'DataCloneError: Proxy object could not be cloned.',
  'timeout exceeded',
  'CanceledError',
  'Request validation failed',
];

export const denyUrls = [
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extensions:\/\//i,
  /^safari-extension:\/\//i,
  /hotjar\.com\//i,
];

export const filterOldBrowsers = () => {
  const parser = new UAParser(navigator.userAgent);
  const browser = parser.getBrowser();

  if (browser.name === 'Chrome' && browser.major < 109) {
    return true;
  }
  if (browser.name === 'Firefox' && browser.major < 54) {
    return true;
  }
  if (browser.name === 'Safari' && browser.major < 10) {
    return true;
  }
  if (browser.name === 'Opera' && browser.major < 38) {
    return true;
  }
  return false;
};

export const filterUnwantedExceptions = (event, hint) => {
  const responseErrorCode = hint?.originalException?.response?.data?.code || null;
  const ignoredErrorCodes = [ERROR_CODES.USER_BLOCKED, ERROR_CODES.QUOTE_NOT_FOUND];

  if (responseErrorCode && ignoredErrorCodes.includes(responseErrorCode)) {
    return true;
  }

  if (event?.exception?.values && event.exception.values[0].value.includes('Non-Error exception captured with keys')) {
    try {
      const { statusCode, stack } = event.extra.__serialized__.error;

      const isDeveloperError = statusCode === 'DEVELOPER_ERROR';
      const isGooglePayNonErrorException = stack.indexOf('gp/p/js/pay.js') !== -1;

      if (isGooglePayNonErrorException && !isDeveloperError) {
        return true;
      }
    } catch (e) {
      // Do not break the flow if error occurs
    }
  } else {
    try {
      const { response } = hint.originalException;

      if (ignoredStatusCodes.includes(response?.status)) {
        return true;
      }
    } catch (e) {
      // Do not break the flow if error occurs
    }
  }

  if (event?.exception?.values && event.exception.values[0].value.includes('Could not find refreshToken')) {
    return true;
  }

  return false;
};
