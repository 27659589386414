import { h, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { isAnyTranslationLoaded, setI18nLanguage } from '@/v1/i18n';

import WidgetWrapper from '@/v1/components/WidgetWrapper';
import { ModalHolder } from '@/v1/components/Modal';

import { useTransactionEventsRegistrator } from '@/v1/composables/useTransactionEventsRegistrator';

import { setAppIdPartnerId } from '@/utils/loadNSure';
import { loadSiftScript } from '@/utils/loadSift';

import { emitToParent } from '@/services/widgetApiEvents';

import { useLogout } from '@/v1/composables/useLogout';
import eventBus from '@/helpers/eventBus';
import { MISSING_REFRESH_TOKEN_EVENT } from '@/v1/packages/authentication/constants';

export default {
  name: 'App',

  setup() {
    const store = useStore();
    const transactionEventsRegistrator = useTransactionEventsRegistrator();

    // TODO: this is temporary solution. We need to display some error modal here.
    watch(
      () => store.getters.hasTranslationsLoadingError,
      hasError => {
        if (hasError) {
          // eslint-disable-next-line no-alert
          // alert('translations not loaded. Please contact your service provider');
          if (isAnyTranslationLoaded.value) {
            store.dispatch('setError', null);
          } else {
            emitToParent('state', {
              state: 'closed',
            });
          }
        }
      },
    );

    watch(() => [
      store.getters.locale,
      store.getters.requestId,
      store.getters.translationResource,
    ], ([locale, requestId, translationResource]) => {
      if (!locale || !requestId) return;

      store.dispatch('setLanguageSwitching', true);
      setI18nLanguage(locale, requestId, translationResource).finally(() => {
        store.dispatch('setLanguageSwitching', false);
      });
    }, {
      immediate: true,
    });

    watch(
      () => store.getters.nSure,
      nsure => {
        setAppIdPartnerId(nsure.appId, nsure.nSurePartnerId);
      },
    );

    watch(
      () => store.getters.sift,
      loadSiftScript,
    );

    const { softLogout } = useLogout();

    onMounted(() => {
      transactionEventsRegistrator.register();
      eventBus.on(MISSING_REFRESH_TOKEN_EVENT, softLogout);
    });

    onUnmounted(() => {
      transactionEventsRegistrator.unregister();
      eventBus.off(MISSING_REFRESH_TOKEN_EVENT, softLogout);
    });

    return () => [
      h(WidgetWrapper),
      h(ModalHolder),
    ];
  },
};
