<template>
  <button
    class="apple-pay-button-with-text btn btn-primary btn-lg"
    :lang="lang"
    :disabled="isDisabled"
    @click.prevent="startSession"
  >
    <span class="apple-pay-button-with-text__image">
      <img
        :src="applePayIconUrl"
      >
    </span>
  </button>
</template>

<script>
import CardPaymentClient from '@/v1/packages/common/services/clients/card-payment-client';
import { captureException } from '@/v1/packages/common/plugins/sentry';
import { mapGetters, mapMutations } from 'vuex';
import { isElectron } from '@/utils/detect';
import { isWebView } from '@/v1/packages/common/services/web-view-service';
import applePayIconUrl from '@/v1/packages/common/assets/images/apple-pay.svg?url';
import { getCrossOriginParams } from '@/v1/services/applePayCrossOriginService';

export default {
  name: 'ApplePayButton',
  props: {
    lang: { type: String, required: true },
    payment: { type: Object, required: true },
    settings: { type: Object, required: true },
    countryCode: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      isCanceled: false,
      isLoading: false,
      applePayIconUrl,
    };
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    checkRequiredFields({ familyName, givenName, locality, addressLines, countryCode, postalCode }) {
      const errors = [];
      const address = addressLines.map(el => el.trim()).filter(el => el).join(', ');

      if (!familyName.trim() || !givenName.trim()) {
        errors.push(new ApplePayError('billingContactInvalid', 'name', this.$t('transaction-flow.apple-pay.validation.name')));
      }

      if (this.aftCheckoutFeature.isEnabled()) {
        if (!locality.trim()) {
          errors.push(new ApplePayError('billingContactInvalid', 'locality', this.$t('validators.billing-address.aft.city.required')));
        }

        if (address.length === 0) {
          errors.push(new ApplePayError('billingContactInvalid', 'addressLines', this.$t('validators.billing-address.aft.address.required')));
        }

        if (!countryCode) {
          errors.push(new ApplePayError('billingContactInvalid', 'countryCode', this.$t('validators.billing-address.aft.country.required')));
        }

        if (!postalCode.trim()) {
          errors.push(new ApplePayError('billingContactInvalid', 'postalCode', this.$t('validators.billing-address.aft.zip.required')));
        }
      }

      if (errors.length > 0) {
        return {
          status: ApplePaySession.STATUS_FAILURE,
          errors,
        };
      }

      return null;
    },
    startSession() {
      if (this.isDisabled) {
        return;
      }
      if (isElectron() || isWebView()) {
        this.$emit('pay', { showPopupPaymentNotPossible: true });
        return;
      }
      this.isCanceled = false;
      this.lock();
      const {
        version,
        supportedNetworks,
        merchantCapabilities,
        paymentLabel: label,
      } = this.settings;
      const total = { label, amount: this.payment.getAmount() };
      const applePaySessionConfig = {
        countryCode: this.countryCode,
        currencyCode: this.payment.getCurrency(),
        requiredBillingContactFields: ['postalAddress'],
        supportedNetworks,
        merchantCapabilities,
        total,
      };

      const session = new ApplePaySession(version, applePaySessionConfig);

      session.onvalidatemerchant = ({ validationURL: validationUrl }) => {
        this.isLoading = true;
        CardPaymentClient.validatePaymentSession({ validationUrl, invoice: this.invoice, ...getCrossOriginParams() })
          .then(({ data }) => {
            if (typeof data !== 'object') {
              this.unlock();
              const error = new Error('Received invalid response for ApplePay!');
              // Extra logging for PD-12454
              captureException({
                error,
                extra: {
                  response: data,
                },
              });

              throw error;
            }
            if (this.isCanceled) return;
            session.completeMerchantValidation(data);
          })
          .catch(error => {
            this.handleApplePayError(error, session);
          })
          .finally(() => {
            this.isLoading = false;
          });
      };

      try {
        session.onpaymentauthorized = ({ payment }) => {
          const { token, billingContact = {} } = payment;
          const {
            givenName = '',
            familyName = '',
            countryCode = '',
            postalCode = '',
            administrativeArea = null,
            locality = '',
            addressLines = [],
          } = billingContact;

          const validationErrors = this.checkRequiredFields(billingContact);
          if (validationErrors) {
            session.completePayment(validationErrors);
            return;
          }

          const payload = {
            paymentToken: token.paymentData,
            cardholderName: `${givenName} ${familyName}`.trim(),
            applePaySession: session,
          };

          if (this.aftCheckoutFeature.isEnabled()) {
            payload.billingAddress = {
              address: addressLines.map(el => el.trim()).filter(el => el).join(', '),
              city: locality.trim(),
              country: countryCode,
              state: countryCode === 'US' ? administrativeArea.trim() : null,
              zip: postalCode.trim(),
            };
          }

          this.$emit('pay', payload);
        };
        const updateParams = { newTotal: total };
        session.onpaymentmethodselected = () => {
          session.completePaymentMethodSelection(updateParams);
        };
        session.onshippingmethodselected = () => {
          session.completeShippingMethodSelection(updateParams);
        };
        session.onshippingcontactselected = () => {
          session.completeShippingContactSelection(updateParams);
        };

        session.oncancel = event => {
          this.isCanceled = true;
          this.unlock();
        };

        session.begin();
      } catch (error) {
        this.handleApplePayError(error, session);
      }
    },
    handleApplePayError(error, applePaySession) {
      this.unlock();
      this.$emit('error', {
        error,
        session: applePaySession,
      });
    },
  },
  computed: {
    ...mapGetters('transaction', ['invoice']),
    ...mapGetters('feature', ['aftCheckoutFeature']),
    isDisabled() {
      return this.disabled || this.isLoading;
    },
  },
};
</script>
<style scoped lang="scss">
.apple-pay-button-with-text {
  cursor: pointer;
  display: flex;
  --apple-pay-scale: 1; /* (height / 32) */

  &__image {
    display: flex;
    height: 100%;
    align-items: center;
  }

  & > .text {
    font-family: -apple-system;
    font-weight: 700;
    margin-right: calc(6px * var(--apple-pay-scale));
  }
}
</style>
