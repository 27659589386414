<template>
  <div
    v-if="!isLoading"
    class="basic-verification-wrapper"
  >
    <h1 class="basic-verification-wrapper__title">
      {{ $t('base-verification.sumsub-widget.title') }}
    </h1>

    <SumsubWebsdk
      :api-url="sumsubConfig.apiUrl"
      :flow-name="sumsubConfig.flowName"
      :access-token="sumsubConfig.accessToken"
      :email="sumsubConfig.email"
      :language="language"
      :new-token-callback="newTokenCallback"
      @applicantStatusCompleted="onApplicantStatusCompleted"
      @error="onError"
      @step="onStep"
      @message="onMessage"
    />

    <div
      v-if="canCancelVerification"
      class="sales-funnel-actions sumsub"
    >
      <div class="sales-funnel-actions__cancel">
        <button
          class="btn-reject"
          data-testid="cancelbutton"
          @click="toggleTransactionCancellationPopup()"
        >
          {{ $t('shared.navigation.button.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import SumsubWebsdk from '@/v1/packages/common/components/sumsub-websdk.vue';
import { errorHandler as SumsubWidgetErrorHandler } from '@/v1/packages/common/services/sumsub';

import { setRoutePreloadState } from '@/v1/router/PreloadRouterView';
import { CRITICAL_ERROR } from '@/v1/router/routes';

import { setVerificationAsLoaded } from '@/services/waitForVerification';

const REVIEW_ANSWER_GREEN = 'GREEN';

const BaseVerificationStep = {
  STEP_BASE_VERIFICATION: 'base-verification',
  STEP_SUMSUB_WIDGET: 'sumsub-widget',
  STEP_PENDING: 'pending',
  STEP_BLOCKED: 'blocked',
};

export default {
  name: 'BasicVerificationScreen',
  components: {
    SumsubWebsdk,
  },
  data() {
    return {
      isLoading: true,
      isSumSubWidgetLoaded: false,
    };
  },
  computed: {
    ...mapGetters('transaction', ['canCancelVerification', 'showTransactionCancellationPopup']),
    ...mapGetters('verification', ['sumsubConfig', 'verification']),
    ...mapGetters('quote', ['hasQuote']),
    ...mapGetters('card', ['selectedCard']),
    ...mapGetters('error', ['isCriticalError']),
    ...mapGetters(['language']),
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
    isCriticalError(newValue) {
      if (newValue && this.$router.history.current.name !== CRITICAL_ERROR) {
        this.$router.push({ name: CRITICAL_ERROR });
      }
    },
    verification: {
      handler() {
        if (this.verification.isUnverified() || this.verification.isRejected()) {
          if (this.$route.name !== BaseVerificationStep.STEP_PENDING) {
            this.$store.commit('verification/unforcePendingScreenForRejected');
            return;
          }
        }

        if (this.verification.isVerified()) {
          if (this.hasQuote) {
            // We are in transaction flow app
            this.$store.dispatch('transaction/init');
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getVerification()
      .finally(() => {
        this.isLoading = false;
        // hotfix: PD-9873. Should be refactored in PD-10013
        document.dispatchEvent(new Event('base-verification-loaded'));
      });
  },
  methods: {
    ...mapActions('verification', ['getVerification']),
    ...mapMutations('transaction', ['toggleTransactionCancellationPopup']),

    onApplicantStatusCompleted(reviewAnswer) {
      this.$store.dispatch('verification/saveLastReviewAnswer', reviewAnswer);

      if (REVIEW_ANSWER_GREEN === reviewAnswer) {
        this.getVerification()
          .then(() => {
            if (this.verification.isRejected()) {
              this.$store.commit('verification/forcePendingScreenForRejected');
            }
          });
      }
    },
    notifySumSubWidgetLoaded() {
      // notify preloader view only once
      if (this.isSumSubWidgetLoaded) return;

      setVerificationAsLoaded();

      // it takes some time to display all elements on the sumsub widget
      nextTick(() => {
        setRoutePreloadState(this.$options.name, true);
        window.scrollTo(0, 0);
      });
      this.isSumSubWidgetLoaded = true;
    },
    onStep() {
      this.notifySumSubWidgetLoaded();
    },
    onMessage(type) {
      // When verification is pending, sumsub not emit `step` message
      // Closest is `applicantStatus` that emits when applicant data is loaded
      if (type === 'idCheck.applicantStatus') {
        if (this.verification.isRejected() || this.verification.isUnverified()) {
          this.notifySumSubWidgetLoaded();
        }
      }
    },
    onError(error) {
      const handleResult = SumsubWidgetErrorHandler.handle(error, this.sumsubConfig.email);

      if (handleResult.isFatal()) {
        this.$router.push({ name: CRITICAL_ERROR });
      } else {
        this.notifySumSubWidgetLoaded();
      }
    },
    newTokenCallback() {
      return this.getVerification().then(() => this.sumsubConfig.accessToken);
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-verification-wrapper {
  display: flex;
  flex-direction: column;

  .hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
  }

  .loader {
    padding-bottom: 100px;
  }

  &__title {
    font-size: rem(28);
    line-height: rem(40);
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 52px;
    text-align: center;

    @media (max-width: $tablet-max) {
      font-size: rem(24);
      line-height: 26px;
      margin-top: 8px;
    }

    @media (max-width: $screen-sm-min) {
      font-size: rem(20);
      line-height: 26px;
      padding-left: 20px;
    }
  }
}

.sales-funnel-actions.sumsub {
  position: relative;
  z-index: 2; // should be less then menu z-index in WidgetMenu.vue https://gitlab.com/techcloud/pb/widget-front/-/blob/d435d3f5c551ebb6f8f94ff8e598ec24916d911d/src/components/WidgetMenu/WidgetMenu.container.vue#L72

  @media screen and (min-width: $screen-lg-min) {
    margin-top: rem(-16);
    margin-bottom: 0;
  }
}
</style>
