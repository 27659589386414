<template>
  <span>
    <Pictogram
      :src="pictogramUrl"
    />
    <Subtitle>{{ $t('transaction-flow.steps.cancelled.subtitle') }}</Subtitle>
    <Paragraph>
      {{ $t('transaction-flow.steps.cancelled.paragraph') }}
    </Paragraph>
    <Actions>
      <template #submit>
        <button
          :disabled="isLocked"
          class="btn btn-lg btn-secondary"
          :class="{ 'is-loading': isLocked }"
          @click="start()"
        >
          {{ $t('shared.navigation.button.start-a-new-transaction') }}
        </button>
      </template>
    </Actions>
  </span>
</template>

<script>
import Paragraph from '@/v1/packages/common/components/paragraph.vue';
import Pictogram from '@/v1/packages/common/components/pictogram.vue';
import Subtitle from '@/v1/packages/common/components/subtitle.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Actions from '../components/actions.vue';
import pictogramUrl from '@/v1/packages/common/assets/images/transaction-canceled.svg?url';

export default {
  components: {
    Subtitle,
    Paragraph,
    Pictogram,
    Actions,
  },
  data: () => ({
    pictogramUrl,
  }),
  computed: {
    ...mapGetters(['isLocked']),
  },
  methods: {
    ...mapMutations(['lock']),
    ...mapActions('transaction', ['repeatTransaction']),
    start() {
      this.lock();
      this.repeatTransaction();
    },
  },
};
</script>
