<template>
  <Modal
    class="popup-payment-not-possible"
    append-to-body
    modal-type="widget"
    size="sm"
    :show="show"
    @close="$emit('close')"
  >
    <ModalHeading class="popup-payment-not-possible__heading">
      <template v-if="isApplePay">
        <img
          :src="applePayIconUrl"
          alt="icon"
          class="popup-payment-not-possible__payment-logo"
        >
      </template>
      <template v-if="isGooglePay">
        <img
          :src="googlePayIconUrl"
          alt="icon"
          class="popup-payment-not-possible__payment-logo"
        >
      </template>
      {{ $t('transaction-flow.payment-not-possible.title', {paymentName}) }}
    </ModalHeading>
    <div class="modal-body">
      <ModalContent
        class="modal-content--lg--px-0"
        size="lg"
      >
        <p class="text-center mb-32">
          {{ $t('transaction-flow.payment-not-possible.message') }}
        </p>
        <ModalFormActions class="mt--sm-24">
          <CopyableButton
            class="btn-lg btn-secondary"
            :class="{'is-loading': isLoading}"
            :has-copy-icon="false"
            :label="$t('transaction-flow.payment-not-possible.copy-link')"
            :value="url"
          />
        </ModalFormActions>
      </ModalContent>
    </div>
  </Modal>
</template>

<script>
</script>

<script setup>
import Modal from '@/v1/packages/common/components/modal.vue';
import ModalContent from '@/v1/packages/common/components/modal-content.vue';
import ModalHeading from '@/v1/packages/common/components/modal-heading.vue';
import ModalFormActions from '@/v1/packages/common/components/modal-form-actions.vue';
import { ref, defineProps, computed, watch, defineEmits } from 'vue';
import { PAYMENT_METHODS } from '@/constants/paymentMethods';
import WidgetServiceV2 from '@/v1/services/widget/widgetService.v2';
import store from '@/v1/store/index';
import CopyableButton from '@/v1/packages/common/components/copyable-button.vue';
import { AppCriticalError } from '@/exceptions/AppCriticalError';
import applePayIconUrl from '@/v1/assets/images/apple-pay-not-possible.svg?url';
import googlePayIconUrl from '@/v1/assets/images/google-pay-not-possible.svg?url';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  paymentName: {
    type: String,
  },
});

const isLoading = ref(false);
const url = ref(null);

const isApplePay = computed(() => props.paymentName === PAYMENT_METHODS.APPLE_PAY);
const isGooglePay = computed(() => props.paymentName === PAYMENT_METHODS.GOOGLE_PAY);
const requestId = computed(() => store.getters.requestId);

watch(
    () => props.show,
    value => {
      if (!value) return;
      initUrlLink();
    },
);

const initUrlLink = async () => {
  isLoading.value = true;
  try {
    const { oneTimeToken } = await WidgetServiceV2.getAuthToken(requestId.value);
    url.value = `${window.location.protocol}//${window.location.host}?requestId=${requestId.value}&oneTimeToken=${oneTimeToken}`;
  } catch (e) {
    throw new AppCriticalError(e.message);
  } finally {
    isLoading.value = false;
  }
};

</script>

<style scoped lang="scss">
.popup-payment-not-possible {
  &__heading {
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 1rem;
  }

  &__payment-logo {
    display: block;
    margin: 0 auto rem(16) auto;
  }
}

@media screen and (max-width: $mobile-max) {
  ::v-deep .btn-secondary {
    width: 100%;
  }
}
</style>
