<template>
  <div
    id="sumsub-websdk-container"
    class="verification-sumsub-frame"
  />
</template>

<script>
import snsWebSdk from '@sumsub/websdk';

import { SumsubWebsdkError } from '../models/sumsub';
import { mapGetters } from 'vuex';

const REVIEW_ANSWERS = ['GREEN', 'RED'];
const SUMSUB_IFRAME_ID = 'sumsub-websdk-iframe';

export default {
  name: 'SumsubWebsdk',
  props: {
    apiUrl: { type: String, reqired: true },
    accessToken: { type: String, reqired: true },
    email: { type: String, reqired: true },
    language: { type: String, reqired: true },
    newTokenCallback: { type: Function, reqired: true },
  },
  computed: {
    ...mapGetters('request', ['requestId', 'invoiceId']),
    ...mapGetters(['themeConfig', 'clientId']),
  },
  mounted() {
    if (this.accessToken === '') {
      throw new Error('Access token is empty.'); // SumSub assumed that we sometimes don't provide token, we need proof of it
    }

    if (window.hj) {
      window.hj('event', 'verification_widget');
    }

    const snsWebSdkInstance = snsWebSdk
      .init(this.accessToken, this.newTokenCallback)
      .withBaseUrl(this.apiUrl)
      .withConf({
        lang: this.language,
        email: this.email,
        uiConf: {
          hotjar: {
            userId: this.clientId,
            userData: {
              email: this.email,
              invoice: this.invoiceId,
            },
          },
        },
      })
      .on('onError', this.sumsubErrorCallback)
      .onMessage(this.sumsubCallback)
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
    if (snsWebSdkInstance.iframe !== undefined) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(import.meta.env.VITE_APP_HOTJAR_ENABLED)) {
        snsWebSdkInstance.iframe.setAttribute('data-hj-allow-iframe', '');
      }
      // we need id and name for iframe tag for testing purposes
      snsWebSdkInstance.iframe.setAttribute('id', SUMSUB_IFRAME_ID);
      snsWebSdkInstance.iframe.setAttribute('name', SUMSUB_IFRAME_ID);
    }
  },
  methods: {
    sumsubCallback(type, payload) {
      if (this.themeConfig.hotJarId && ['idCheck.onInitialized', 'idCheck.onReady'].includes(type)) {
        if (window.hj) {
          window.hj('event', 'verification_started');
        }
      }
      if (
        type === 'idCheck.applicantStatus'
        && payload.reviewStatus === 'completed'
        && REVIEW_ANSWERS.includes(payload.reviewResult.reviewAnswer)
      ) {
        this.$emit(
          'applicantStatusCompleted',
          payload.reviewResult.reviewAnswer,
        );
      }
      if (
        type === 'idCheck.actionStatus'
        && payload.reviewStatus === 'completed'
        && REVIEW_ANSWERS.includes(payload.reviewResult.reviewAnswer)
      ) {
        this.$emit('actionStatusCompleted', payload.reviewResult.reviewAnswer);
      }
      if (
        type === 'idCheck.stepCompleted'
        || type === 'idCheck.onStepInitiated'
      ) {
        this.$nextTick(() => {
          document.querySelector(`#${SUMSUB_IFRAME_ID}`).scrollIntoView(),
          // To provide bindpoint when current step is completely loaded
          // It is required for transitions to avoid sumsub loader
          this.$emit('step');
        });
      }

      this.$emit('message', type, payload);
    },
    sumsubErrorCallback(error) {
      if (window.hj) {
        window.hj('event', 'verification_error');
      }
      const hiddenAccessToken = this.accessToken === ''
        ? 'empty'
        : `${this.accessToken.substr(0, 10)}...`;
      const context = {
        accessToken: hiddenAccessToken,
        lang: this.language,
        email: this.email,
      };
      this.$emit('error', new SumsubWebsdkError(error, context));
    },
  },
};
</script>

<style scoped lang="scss">
/* Styles based on .verification-video-frame */
.verification-sumsub-frame {
  @media screen and (min-width: 482px) {
    margin: -2.5rem calc(-50vw + #{rem(240)}) -3.5rem;
  }

  @media screen and (min-width: 676px) {
    margin: -2.5rem rem(-104) -3.5rem;
  }
}
</style>
