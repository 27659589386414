import {
  Callout,
  NoticeCallout,
  SuccessCallout,
  WarningCallout,
} from '@/v1/packages/common/models/callout';

export default class CalloutFactory {
  constructor(transaction, verification, extraVerification, payment) {
    this.transaction = transaction;
    this.verification = verification;
    this.extraVerification = extraVerification;
    this.payment = payment;
  }

  build() {
    if (this.transaction.isCompleted()) {
      return new SuccessCallout('transaction-flow.status.success');
    }
    if (this.isPaymentReceived()) {
      return new Callout(
        'transaction-flow.status.payment-received',
        'text-green',
      );
    }
    if (this.transaction.isRejected()) {
      return new WarningCallout('transaction-flow.status.rejected');
    }
    if (this.transaction.isPaymentError()) {
      return new WarningCallout('transaction-flow.status.payment-error');
    }
    if (this.transaction.isCancelled()) {
      return new NoticeCallout('transaction-flow.status.cancelled', 'text-red');
    }
    if (this.verification.isVerified() && this.transaction.isPayoutDetails()) {
      return new SuccessCallout(
        'transaction-flow.status.verification-approved',
        'text-green',
      );
    }
    if (this.isVerificationRejected()) {
      return new WarningCallout(
        'transaction-flow.status.verification-rejected',
      );
    }
    if (this.payment.refused()) {
      return new Callout('transaction-flow.status.payment-refused', 'text-red');
    }

    return new Callout();
  }

  isPaymentReceived() {
    if (
      this.payment.isCrypto()
      && (this.transaction.isPaymentWaiting()
        || this.transaction.isPaymentReview())
    ) {
      return true;
    }

    return this.transaction.isPayoutWaiting();
  }

  isVerificationRejected() {
    return (
      this.extraVerification.isRejected()
      && !(
        this.transaction.isRejected()
        || this.transaction.isCancelled()
        || this.transaction.isPaymentError()
      )
    );
  }
}
