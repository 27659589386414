<template>
  <div
    class="form-wallet-select"
    :class="{
      'has-error': error,
      'is-disabled': isDisabled,
      'has-tip': hasTipSlot,
    }"
  >
    <div
      v-if="label || hasTipSlot"
      class="form-wallet-select__top"
    >
      <label
        v-if="label"
        :for="id"
        class="form-wallet-select__label"
      >
        {{ label }}
      </label>

      <span class="form-wallet-select__tip">
        <slot name="tip" />
      </span>
    </div>

    <div
      class="form-wallet-select__input"
      :class="{ 'has-focus': hasFocus }"
    >
      <v-select
        label="blockchain"
        :options="options"
        :value="value"
        :model-value="value"
        :clearable="false"
        :searchable="false"
        :disabled="isDisabled"
        :reduce="wallet => getAddressFromAddresses(wallet)"
        @search:focus="handleFocus"
        @search:blur="handleBlur"
        @input="setSelected"
        @update:modelValue="setSelected"
      >
        <template
          slot="selected-option"
          slot-scope="option"
        >
          {{ getWalletName(option) }}
        </template>

        <template
          slot="option"
          slot-scope="option"
        >
          <div class="form-wallet-select-item">
            <div class="form-wallet-select-item__primary">
              <div class="form-wallet-select-item__title">
                {{ getWalletName(option) }}
              </div>

              <div class="form-wallet-select-item__amount">
                {{ getAmountFromBalance(option.balance) }}
              </div>
            </div>

            <template>
              <div class="form-wallet-select-item__hash">
                <AddressMask
                  :address="getAddressFromAddresses(option)"
                  :has-copy="false"
                />
              </div>
            </template>
          </div>
        </template>
      </v-select>
    </div>

    <span
      v-if="error"
      class="form-wallet-select__error"
    >{{ error }}</span>

    <div class="form-wallet-select__postfix">
      <div
        v-if="isExistAddress"
        class="form-wallet-select__postfix-hash"
      >
        <AddressMask
          :address="selectedAddress"
          :has-copy="false"
        />
      </div>

      <div
        v-if="isExistAmount"
        class="form-wallet-select__postfix-amount"
      >
        {{ selectedFullAmount }}
      </div>
    </div>
  </div>
</template>

<script>
// TODO should be used from UI kit. DRY violation
// @see core/components/front/.../components/WebWallets/WalletSelect.vue
import { mapGetters } from 'vuex';
import { formatNumberForCrypto } from '../helpers/numberFormatter';
import AddressMask from './address-mask.vue';

export default {
  name: 'WalletSelect',

  components: { AddressMask },

  props: {
    id: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    error: { type: String, required: false, default: '' },
    isDisabled: { type: Boolean, required: false, default: false },
    value: { type: [Number, String], required: false, default: null },
    options: { type: Array, required: true },
  },

  data() {
    return {
      hasFocus: false,
    };
  },

  computed: {
    ...mapGetters(['language']),
    selectedObject() {
      const obj = this.options.filter(item => item.addresses.find(({ address }) => address === this.value));

      return obj[0];
    },

    hasTipSlot() {
      return !!this.$slots.tip;
    },

    isExistAmount() {
      return !!(
        this.selectedObject
        && this.selectedObject.balance
        && 'amount' in this.selectedObject.balance
      );
    },

    selectedFullAmount() {
      return this.getAmountFromBalance(this.selectedObject.balance);
    },

    isExistAddress() {
      return !!(
        this.selectedObject
        && this.selectedObject.addresses
        && Array.isArray(this.selectedObject.addresses)
        && this.selectedObject.addresses.length > 0
      );
    },

    selectedAddress() {
      return this.getAddressFromAddresses(this.selectedObject);
    },
  },

  methods: {
    setSelected(value) {
      this.$emit('input', value);
    },

    handleFocus() {
      this.hasFocus = true;
    },

    handleBlur() {
      this.hasFocus = false;
    },

    getWalletName(option) {
      return option.name;
    },

    getAddressFromAddresses(wallet) {
      if (wallet === undefined) {
        return '';
      }

      const { addresses } = wallet;

      return addresses && addresses[0] ? addresses[0].address : '';
    },

    getAmountFromBalance(balance) {
      const { amount, currency } = balance;
      return `${formatNumberForCrypto(
        amount,
        this.language,
        currency,
      )} ${currency}`;
    },
  },
};
</script>

<style scoped lang="scss">
.form-wallet-select {
  width: 100%;
  position: relative;

  &.is-disabled {
    pointer-events: none;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: rem(16);
    line-height: 1.25;
  }

  &__tip {
    color: $grey-30;
    font-size: rem(14);
    line-height: 1.14;
  }

  &.is-disabled &__label {
    color: $grey-30;
  }

  &__top {
    margin-bottom: rem(4);
  }

  &__input {
    position: relative;
    display: block;
    width: 100%;
    background-color: $white;
    border: none;
    border-radius: rem(6);
    font-weight: 700;
    font-size: rem(16);
    line-height: 1.25;
    box-shadow: 0 0 0 2px $black-10;
    transition: box-shadow 0.2s linear;
  }

  &.is-disabled &__input {
    background-color: $grey-04;
    box-shadow: 0 0 0 2px $grey-04;
    color: $grey-30;
  }

  &__input::after {
    position: absolute;
    right: rem(14);
    top: rem(14);
    content: '\e901';
    display: inline-block;

    @include inline-icon;
  }

  &__input.has-focus::after {
    transform: rotate(180deg);
  }

  [dir='rtl'] &__input:after {
    left: rem(14);
    right: unset;
  }

  &__input:hover {
    box-shadow: 0 0 0 2px $black-20;
  }

  &__input.has-focus {
    outline: none;
    box-shadow: 0 0 0 1px $violet-60;
  }

  &__input:disabled {
    background-color: $grey-04;
    color: $grey;
    box-shadow: 0 0 0 1px $grey-04;
  }

  &__input.is-regular {
    font-family: $font-family;
  }

  &.has-error &__input {
    box-shadow: 0 0 0 2px $red;
  }

  &.has-error &__input:hover {
    box-shadow: 0 0 0 2px rgba(231, 60, 64, 0.25);
  }

  &__error {
    display: block;
    margin-top: rem(4);
    font-size: rem(12);
    line-height: 1.33;
    color: $red;
  }

  &__postfix {
    display: flex;
    justify-content: space-between;
    color: $grey-30;
    font-size: rem(12);
    line-height: rem(16);
    margin-top: rem(8);
  }

  &__postfix-hash {
    width: 100%;
    word-wrap: break-word;
  }

  &__postfix-amount {
    font-weight: 700;
  }

  @media screen and (max-width: $screen-md-max) {
    &.has-tip {
      padding-bottom: rem(28);
    }

    &.has-tip &__tip {
      position: absolute;
      bottom: rem(2);
    }

    &__postfix-amount {
      display: none;
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    &__postfix-hash {
      max-width: 270px;
    }
  }
}

.form-wallet-select-item {
  display: block;
  width: 100%;

  &__title {
    font-size: rem(14);
    line-height: rem(16);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__amount {
    font-size: rem(12);
    line-height: rem(16);
    color: $black;
    margin-top: rem(4);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__hash {
    font-family: $font-family;
    font-size: rem(12);
    line-height: 1.33;
    margin-top: rem(8);
    color: $black-30;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: $screen-lg-min) {
    &__primary {
      display: flex;
      justify-content: space-between;
    }

    [dir='rtl'] &__primary {
      flex-direction: row-reverse;
    }

    &__amount {
      margin-top: 0;
    }

    &__hash {
      margin-top: rem(2);
    }
  }
}
</style>

<style lang="scss">
@import '@/v1/packages/common/assets/scss/global/lib/vselect';

.form-wallet-select__input {
  [dir='rtl'] & .vs__selected-options {
    flex-direction: row-reverse;
  }

  & .vs__selected-options {
    cursor: pointer;
  }

  & .vs__search {
    height: rem(48);
    -moz-appearance: none;
    border: none;
  }

  & .vs__selected {
    padding: 0 rem(16);
    line-height: rem(48);
  }

  & .vs__dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px $black-15;
    border-radius: rem(10);
    padding: rem(6);
    max-height: rem(256);
  }

  & .vs__dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  & .vs__dropdown-option {
    padding: rem(8);
    border-radius: rem(4);
  }

  & .vs__dropdown-option:hover,
  & .vs__dropdown-option--highlight {
    background-color: $grey-04;
  }

  & .vs__dropdown-option--selected {
    color: $grey-30;
  }
}
</style>
